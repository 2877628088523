import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import PropTypes from 'prop-types';
import withWidth from '@material-ui/core/withWidth';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import compose from 'recompose/compose';
import Map from './Map.jsx';
import Banner from '../Banner/Banner';
import Textfields from './TextFields';

const styles = theme => ({
	root1: {
		height: 'auto',
		width: '100%',
		margin: '30px auto',
	},
	root2: {
		height: 'auto',
		width: '100%',
		color: theme.palette.secondary.dark,
		margin: '0 auto',
		padding: theme.spacing(1),
	},
	root3: {
		height: 'auto',
		width: '100%',
		margin: '0 auto',
		padding: theme.spacing(1),
		paddingTop: theme.spacing(4),
		paddingLeft: theme.spacing(4),
		[theme.breakpoints.down('sm')]: {
			paddingTop: 0,
			paddingLeft: 0,
		},
	},
});

class ContactUs extends React.Component {
	// constructor() {
	// 	super();
	// 	// this.createInfoWindow = this.createInfoWindow.bind(this)
	// }

	// createInfoWindow(e, map) {
	// 	const infoWindow = new window.google.maps.InfoWindow({
	// 		content: '<div id="infoWindow" />',
	// 		position: { lat: e.latLng.lat(), lng: e.latLng.lng() }
	// 	})
	// 	infoWindow.addListener('domready', e => {
	// 		render(<InfoWindow />, document.getElementById('infoWindow'))
	// 	})
	// 	infoWindow.open(map)
	// }

	// handleChange = prop => event => {
	// 	this.setState({ [prop]: event.target.value });
	// };

	render() {
		const {
			classes,
			id,
			width,
		} = this.props;

		return (
			<ScrollAnimation
				animateIn="fadeInLeft"
				duration={3}
				animateOnce={true}
			>
				<Grid
					id={id}
					container
					direction={
						(width === 'xs' || width === 'sm' || width === 'md')
							?
							'column-reverse'
							:
							'row'
					}
					className={classes.root1}
				>
					<Grid
						item
						xs={12}
						md={6}
						className={classes.root3}
					>
						<Map
							id="myMap"
						// options={{
						// 	center: { lat: 23.746466, lng: 90.376015 },
						// 	zoom: 14
						// }}
						// onMapLoad={map => {
						// 	const marker = new window.google.maps.Marker({
						// 		position: { lat: 23.746466, lng: 90.376015 },
						// 		map: map,
						// 		title: 'Hello Aubichol!'
						// 	});
						// 	marker.addListener('click', e => {
						// 		this.createInfoWindow(e, map)
						// 	})
						// }}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						md={6}
						className={classes.root2}
					>
						<Banner
							title='Contact Us'
							variant='h4'
							flag='1'
						/>
						<Banner
							title='We are just a ping away'
							variant='subtitle1'
							flag='0'
						/>
						<Textfields />
					</Grid>
				</Grid>
			</ScrollAnimation>
		);
	}
}

ContactUs.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default compose(
	withStyles(styles), withWidth())(ContactUs);