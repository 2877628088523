import React from 'react';
import {
    Divider,
    CssBaseline,
} from '@material-ui/core';
import Appbar from '../Appbar/index';
import Team from '../Team/team';
import Footer from '../Footer';
import tileData from '../tileData';

const PastEmployees = () => {
    document.title = "Aubichol Intelligent Technologies";

    return (
        <div style={{ overflowX: 'hidden', minHeight: '100vh', marginTop: 60 }}>
            <CssBaseline />

            <Appbar />

            <Team id="team" title='Past Employees' data={tileData.pastEmployees} flag2={0} />

            <Divider
                variant="middle"
                style={{
                    width: '70%',
                    margin: '0 auto',
                }}
            />

            <Divider />

            <Footer />
        </div>
    )
};
export default PastEmployees;