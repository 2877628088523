import React from 'react';
import {
	Divider,
	CssBaseline,
} from '@material-ui/core';
import Appbar from '../Appbar/index';
import Home from '../Home/home';
import Service from '../Services/index';
import Workflow from '../Workflow/Workflow';
import Product from '../Products/Product';
import Contact from '../ContactUs/index';
import Clients from '../Clients/Clients';
import Team from '../Team/team';
import Footer from '../Footer';
import tileData from '../tileData';

const Index = () => {
	document.title = "Aubichol Intelligent Technologies";

	return (
		<div style={{ overflowX: 'hidden' }}>
			<CssBaseline />

			<Appbar />

			<Home id="home" />

			<Divider
				variant="middle"
				style={{
					width: '70%',
					margin: '10px auto',
				}}
			/>

			<Team id="team" title='The Aubichol Team' data={tileData.team} flag2={1} />

			<Divider
				variant="middle"
				style={{
					width: '70%',
					margin: '10px auto',
				}}
			/>

			<Service id="services" />

			<Divider
				variant="middle"
				style={{
					width: '70%',
					margin: '10px auto',
				}}
			/>

			<Workflow id="workflow" />

			<Divider
				variant="middle"
				style={{
					width: '70%',
					margin: '10px auto',
				}}
			/>

			<Product id="product" />

			<Divider
				variant="middle"
				style={{
					width: '70%',
					margin: '10px auto',
				}}
			/>

			{/*<Contributors />

		<Divider />*/}

			<Clients id="clients" />

			<Divider
				variant="middle"
				style={{
					width: '70%',
					margin: '10px auto',
				}}
			/>

			<Contact id="contact" />

			<Divider />

			<Footer />
		</div>
	)
};
export default Index;
