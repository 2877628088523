import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function CustomizedSnackbars({
    sev,
    message,
}) {
    const [open, setOpen] = React.useState(true);
    React.useEffect(() => {
        if (message.length) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [sev, message]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={sev}>
                {message}
            </Alert>
        </Snackbar>
    );
};