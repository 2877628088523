const data = [
    {
        icon: 'https://i.ibb.co/Xzhr6nd/technology-1.png',
        title: 'Technologies',
        des: `We crave to master the intimate details of modern technologies. We believe that a technology is there for a reason. So, we learn it to upgrade our ideas and integrate them with our existing solutions. We focus on the requirements first. Then, we choose our technologies that are capable to solve the given requirements in the best possible way.`
    },
    {
        icon: 'https://i.ibb.co/F5BFxPr/artificial-intelligence-1.png',
        title: 'Intelligence',
        des: `Intelligence is becoming an usual part of the modern day software. We are interested in integrating intelligence to enhance the quality and maintanance of our products.`
    },
    {
        icon: 'https://i.ibb.co/JpZx2mn/engineering.png',
        title: 'Automation',
        des: 'Automation is an integral part of our development lifecycle. We encourage automation to reduce development time, to increase software accuracy and to improve the satisfaction for the entire developer experience.'
    },
]

export default data;