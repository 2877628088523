import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import SentimentVerySatisfied from '@material-ui/icons/SentimentVerySatisfied';

const styles = theme => ({
	root1: {
		//color: theme.palette.secondary.dark,
		padding: theme.spacing(1),
		height: 'auto',
		backgroundColor: 'transparent',
		margin: '0 auto',
		width: '100%',
		lineHeight: 1.8
	},
	icon: {
		fontSize: '50px',
		color: theme.palette.secondary.dark,
		verticalAlign: 'middle',
		position: 'static',
		margin: '0 auto'
	},
})

const Banner = ({
	classes,
	variant,
	title,
	flag,
	...props
}) => (
	<Typography
		variant={variant}
		color="inherit"
		className={classes.root1}
		gutterBottom
		align="center"
		{...props}
	>
		{title}
		<br />
		{flag === '1' ?
			<SentimentVerySatisfied className={classes.icon} /> : null}
	</Typography>

)

Banner.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Banner);
