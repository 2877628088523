import React from 'react';
import Footerlast from './Footerlast.jsx';
import Footerfirst from './Footerfirst.jsx';
import {Divider} from '@material-ui/core';

const Footer = () => (
	<div>
		<Footerfirst/>
		<Divider />
		<Footerlast/>
	</div>
)

export default Footer;
