import React, {
	useState
} from 'react';
import PropTypes from 'prop-types';
import axios from "axios";
import { withStyles } from '@material-ui/core/styles';
import {
	Grid,
	Button,
	TextField,
} from '@material-ui/core';
import Alert from './Alert.jsx';
import { data, initialState } from './Data';

const styles = theme => ({
	root2: {
		width: '100%',
		color: theme.palette.secondary.dark,
		margin: theme.spacing(1),
		padding: theme.spacing(1),
	},

	textField2: {
		padding: theme.spacing(1),
		margin: theme.spacing(1),
		height: 'auto',
		width: 'auto',
		color: theme.palette.primary.dark,
		'& .MuiFormControl-root': {
			padding: '8px 8px 8px 0px',
			// margin: theme.spacing(2),
		}
	},
	textField3: {
		padding: theme.spacing(1),
		margin: theme.spacing(1),
		height: 'auto',
		width: 'auto',
		color: theme.palette.secondary.dark,
		'& .MuiFormControl-root': {
			padding: '8px 8px 8px 0px',
			// margin: theme.spacing(2),
		}
	},
	textfield: {
		// 'label + &': {
		// 	marginTop: theme.spacing(3),
		// },
		borderRadius: 4,
		backgroundColor: theme.palette.common.white,
		// border: '1px solid #ced4da',
		fontSize: 16,
		color: theme.palette.primary.dark,
		// transition: theme.transitions.create(['border-color', 'box-shadow']),
		// borderColor: '#80bdff',
		// boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
	},
});

const d = {
	success: 'Your message is sent! Thanks for being with Aubichol!',
	error: 'Something went wrong. Please try again later.'
}

const TextFields = ({
	classes,
}) => {
	const [
		state,
		setState
	] = useState({
		name: '',
		email: '',
		message: '',
	});
	const [alert, setAlert] = useState('');

	const onChange = key => event => {
		setState({
			...state,
			[key]: event.target.value,
		});
	};

	const handleSubmit = () => {
		let s = '';

		if (state.name === '') {
			s += 'Name field can not be empty.';
		}

		if (state.email === '') {
			s += (s === '' ? '' : ' ') + 'Email field can not be empty.';
		}

		if (state.message === '') {
			s += (s === '' ? '' : ' ') + 'Message field can not be empty.';
		}

		if (s) {
			setAlert(s);
			return;
		}
		// e.preventDefault();
		axios.post('https://aubichol.com/api/public/contact', state, { timeout: 10000 })
			.then((response) => {
				setAlert('success');
			}
			)
			.catch((error) => {
				setAlert('error');
			});
	};

	return (
		<Grid
			container
			direction="column"
			justify="center"
			alignItems="center"
			className={classes.root2}
			spacing={2}
		>
			<Grid
				item
				xs={12}
				lg={10}
				className={classes.textField2}
			>
				{
					data.map(
						(i, index) => (
							<TextField
								key={index}
								placeholder={i.label}
								label={i.label}
								required
								// helperText={`Plaese Enter ${i.label}`}
								value={state[i.keyval]}
								style={
									(i.keyval === 'name' || i.keyval === 'email')
										?
										{ width: '50%' }
										:
										{ width: '100%' }
								}
								className={classes.textfield}
								multiline={i.keyval === 'message' ? true : false}
								rows={i.keyval === 'message' ? 10 : 1}
								onChange={onChange(i.keyval)}
							/>
						)
					)
				}

			</Grid>
			<Grid
				item
				xs={12}
			>
				<Button
					variant="outlined"
					onClick={() => {
						handleSubmit();
						setState(initialState);
					}}
					className={classes.textField3}
				>
					Send Message
				</Button>
			</Grid>
			{
				alert !== ''
					?
					<Alert
						sev={
							(
								alert === 'success'
								||
								alert === 'error'
							)
								?
								alert
								:
								'warning'
						}
						message={
							(
								alert === 'success'
								||
								alert === 'error'
							)
								?
								d[alert]
								:
								alert
						}
						op={true}
					/>
					:
					null
			}
		</Grid>
	);
}


TextFields.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TextFields);
