import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import Carousel, { autoplayPlugin } from '@brainhubeu/react-carousel';
import {
	makeStyles,
	IconButton,
} from '@material-ui/core';
import {
	KeyboardArrowLeft,
	KeyboardArrowRight,
} from '@material-ui/icons';
import '@brainhubeu/react-carousel/lib/style.css';
import tutorialSteps from '../assets/data/products';
import Banner from '../Banner/Banner';

const useStyles = makeStyles(
	theme => (
		{
			gridList: {
				height: 'auto',
				width: '100%',
				margin: '20px auto',
				color: theme.palette.secondary.dark,
				'& .BrainhubCarousel__dots .BrainhubCarousel__dot::before': {
					background: theme.palette.secondary.dark,
					width: '12px',
					height: '3px',
					borderRadius: 0
				}
				//outline: 'solid',
			},
			container: {
				width: '60%',
				height: 'auto',
				margin: '0 auto',
				padding: theme.spacing(2),
				textDecoration: 'none',
				color: theme.palette.primary.dark,
				[theme.breakpoints.down('sm')]: {
					padding: 0,
					width: '90%',
				},
			},
			grid3: {
				display: 'block',
				objectFit: 'cover',
				padding: 0,
				// filter: 'brightness(100%)',
				// '&:hover': {
				// 	filter: 'contrast(120%) ',
				// 	transform: 'scale(1.1)'
				// },
				// transition: 'transform 1s ease-in-out',
				marginBottom: 8,
			},
			carousel: {
				margin: '8px 8px 60px 108px',
				padding: '8px 8px 8px 8px',
				[theme.breakpoints.down('sm')]: {
					padding: '2px 2px 2px 2px',
					margin: '8px 8px 60px 8px',
				},
			},
			carouselRight: {
				margin: '8px 108px 60px 8px',
				padding: '8px 8px 8px 8px',
				[theme.breakpoints.down('sm')]: {
					padding: '2px 2px 2px 2px',
					margin: '8px 8px 60px 8px',
				},
			},
			brainhub: {
				'& .BrainhubCarousel__dots .BrainhubCarousel__dot': {
					background: theme.palette.secondary.dark,
				}
			}
		}
	)
);

const Products = ({
	id
}) => {
	const classes = useStyles();

	return (
		<ScrollAnimation
			animateIn="fadeInLeft"
			duration={3}
			animateOnce={true}
		>
			<div
				id={id}
				className={classes.gridList}
			>
				<Banner
					title='Want to see some of our products? Here they are'
					variant='h4'
					align='center'
					color='inherit'
					flag='0'
				/>
				<br />
				<Carousel
					plugins={[
						'infinite',
						'arrows',
						{
							resolve: autoplayPlugin,
							options: {
								interval: 2000,
							}
						},
					]}
					animationSpeed={1000}
					slidesPerPage={1}
					arrowLeft={
						<IconButton
							className={classes.carousel}
							style={{
								fontSize: '2em'
							}}
							color='inherit'
						>
							<KeyboardArrowLeft fontSize='large' />
						</IconButton>
					}
					arrowRight={
						<IconButton
							className={classes.carouselRight}
							style={{
								fontSize: '2em'
							}}
							color='inherit'
						>
							<KeyboardArrowRight fontSize='large' />
						</IconButton>
					}
					infinite
					dots
					addArrowClickHandler
					className={classes.brainhub}
				>
					{
						tutorialSteps.map(
							(step, index) => (
								<a
									href={step.link}
									key={index}
									title={step.label}
									className={classes.container}
								>
									<div align='center'>
										<img
											src={step.imgPath}
											className={classes.grid3}
											alt="featured"
											width='100%'
											height='auto'
										/>
									</div>
									<Banner
										title={step.label}
										variant="h6"
										flag='0'
										style={{
											color: '#01579b',
											// fontWeight: 'medium'
										}}
									/>
									<Banner
										title={step.des}
										variant="subtitle1"
										flag='0'
									/>
								</a>
							)
						)
					}
				</Carousel>
			</div>
		</ScrollAnimation>
	);
}

export default Products; 
