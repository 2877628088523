export const data = [
    {
        label: 'Name',
        keyval: 'name',
        required: true,
    },
    {
        label: 'Email',
        keyval: 'email',
        required: true,
    },
    {
        label: 'Message',
        keyval: 'message',
        required: true,
    }
];

export const initialState = {
    name: '',
    email: '',
    message: '',
}