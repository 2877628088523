const data = [
    {
        Name: 'S. M Rayhan',
        imgPath: 'https://i.ibb.co/3CJF64X/suitsarto.jpg',
        des: 'COO, Suit & Sarto',
        detail: 'The best thing about Aubichol is it’s team. It’s hard to believe so many great talents like them working at one place. Always helpful and easy to reach. Continuous support and communication are two of their strongest strengths, apart from programming skills of course! CEO Mr. Tarif Ezaz looks into problems directly, which is super helpful. I wish them all the best and recommend them without any hesitation.',
    },
    {
        Name: 'Nurul Islam Topu',
        imgPath: 'https://i.ibb.co/jhttk9b/gaachwala.jpg',
        des: 'CEO, Gaachwala',
        detail: 'Aubichol Intelligent Technologies is so friendly and helpful. Their relentless efforts helped me to learn a lot from them. Their assistance to build my website was so demonstrative and objective. This company got some talent.',
    }
];

export default data;
