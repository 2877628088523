import React from 'react';
import {
	MuiThemeProvider,
	createMuiTheme
} from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import {
	BrowserRouter as Router,
	Route
} from "react-router-dom";
import Index from './Index/index.jsx';
import PastEmployees from './PastEmployees/index.jsx';
import CssBaseline from '@material-ui/core/CssBaseline';

const theme = createMuiTheme({
	palette: {
		primary: {
			main: '#78909c', //the darker gray blue one for appbar smaller and some texts
			dark: '#37474f'  //darker blue
		},
		secondary: {
			main: '#90caf9', //lighter blue
			dark: '#01579b', //darkest blue
		},
	},
	typography: {
		fontFamily: "PT Serif",
		useNextVariants: true,
		lineHeight: 2.5
	}
});

const styles = {
	'@global': {
		'html, body, #root': {
			width: '100%',
			maxWidth: '100vw',
			height: 'auto',
			minHeight: '100vh',
			justifyContent: 'center',
			alignItems: 'center',
			overflowX: 'hidden',
			padding: 0,
		},
	},
};

const App = () => (
	<Router>
		<MuiThemeProvider theme={theme}>
			<CssBaseline />
			<Route exact path="/" component={Index} />
			<Route path="/past-employees" component={PastEmployees} />
		</MuiThemeProvider>
	</Router>
);

export default withStyles(styles)(App);
