const data = [
    {
        icon: 'https://i.ibb.co/6Ffx762/quality.png',
        title: 'Quality',
        des: 'Quality becomes our first priority when we focus on the efficiency of our product. Our target is not just to get something work. We strive to achieve the optimal quality in our products.'
    },
    {
        icon: 'https://i.ibb.co/mGKvmyL/scalability.png',
        title: 'Scalable',
        des: `Our applications are targetted for a large number of audience. Even if our client's initial goal is to start slow, we design and develop our products to accomodate rapid growth. This approach motivates our clients to focus on their growth.`
    },
    {
        icon: 'https://i.ibb.co/kxYNTXs/TDD.png',
        title: 'Test Driven',
        des: 'We spend a lot of our engineering effort to figure out all the different scenarios that can occur in our products. We also workout the failing points of each of the scenarios.'
    },
    {
        icon: 'https://i.ibb.co/ts723HK/mainatinable.png',
        title: 'Maintanable',
        des: 'We ensure maintanability to reduce our debugging time during production. Maintanable code helps us finding bugs fast. It also helps us to incorporate enhancements and integrated improvements.'
    },
]

export default data;