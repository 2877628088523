import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
	Grid,
	Typography,
	Paper,
} from '@material-ui/core';
import {
	Call,
	Email,
	LocationOn,
} from '@material-ui/icons';

const styles = theme => ({
	root: {
		height: 'auto',
		width: '100%',
		margin: '0 auto',
		backgroundColor: theme.palette.primary.dark,
		color: 'white',
	},
	paper1: {
		padding: theme.spacing(1),
		textAlign: 'left',
		width: '100%',
		color: 'white',
		[theme.breakpoints.down('sm')]: {
			textAlign: 'center',
			margin: '0 auto'
		},
	},
	paper2: {
		textAlign: 'right',
		padding: theme.spacing(2),
		background: 'transparent',
		width: '100%',
		margin: '0 auto',
		color: 'white',
		[theme.breakpoints.down('sm')]: {
			textAlign: 'center',
			padding: theme.spacing(1),
		},
	},
	paper3: {
		height: 'auto',
		padding: theme.spacing(1),
		width: '100%',
		margin: '0 auto',
		backgroundColor: 'transparent',
		[theme.breakpoints.down('sm')]: {
			textAlign: 'center',
		},
	},
	icon: {
		fontSize: '20px',
		color: 'white',
		display: 'inline-flex',
		verticalAlign: 'middle',
		position: 'static',
		paddingRight: '5px',
	},
});

const Footerfirst = ({
	classes,
}) => (
		<Grid
			container
			justify='center'
			alignItems='center'
			spacing={2}
			className={classes.root}
		>
			<Grid
				item
				xs={12}
				md={6}
			>
				<Paper
					elevation={0}
					className={classes.paper3}
				>
					<Typography
						variant="h6"
						color="inherit"
						className={classes.paper1}
					>
						Aubichol IT
				</Typography>
					<Typography
						variant="body1"
						color="inherit"
						className={classes.paper1}
					>
						We mean each line of code
				</Typography>
				</Paper>
			</Grid>
			<Grid
				item
				xs={12}
				md={6}
			>
				<Paper
					elevation={0}
					color="inherit"
					className={classes.paper2}
				>
					<Typography
						variant="body1"
						color="inherit"
						gutterBottom
					>
						<Call className={classes.icon} />
					+88 01727192452
				</Typography>
					<Typography
						variant="body1"
						color="inherit"
						gutterBottom
					>
						<Email className={classes.icon} />
					tarifezaz@aubichol.com
				</Typography>
					<Typography
						variant="body1"
						color="inherit"
						gutterBottom
					>
						<LocationOn className={classes.icon} />
					House 37, Road 3, Dhanmondi R/A
				</Typography>
				</Paper>
			</Grid>
		</Grid>
	);

Footerfirst.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Footerfirst);
