const tileData = {
	team: [
		{
			Id: 'Mohammad-Abdullah-Matin-Khan',
			img: 'https://i.ibb.co/MkhKyjK/Zarzis.jpg',
			title: 'Mohammad Abdullah Matin Khan',
			subtitle: 'Software Engineer & Co-founder',
		},
		{
			Id: 'Shajia-Annoor',
			img: 'https://i.ibb.co/9T3RG4C/Joty.jpg',
			title: 'Shajia Annoor',
			subtitle: 'Software Engineer & Co-founder',
		},
		{
			Id: 'Tarif-Ezaz',
			img: 'https://i.ibb.co/Bqh4rXH/Tarif.jpg',
			title: 'Tarif Ezaz',
			subtitle: 'Software Engineer & Co-founder',
		},
		{
			Id: 'Rakin-Ishmam',
			img: 'https://i.ibb.co/TrTtH8V/Rakin.jpg',
			title: 'Mir Rakin Ishmam',
			subtitle: 'Software Engineer & Technical Advisor',
		},
		{
		 	Id: 'Saiful-Bari',
		 	img: 'https://i.ibb.co/rFQLRb2/Bari.jpg',
		 	title: 'M Saiful Bari',
		 	subtitle: 'Machine Learning Advisor',
		},
		{
			Id: 'Ahmmed-Sakib-Noman',
			img: 'https://i.ibb.co/GdMHK46/IMG-20240229-WA0001.jpg',
			title: 'Ahmmed Sakib Noman',
			subtitle: 'Junior Software Development Engineer',
		},
		{
			Id: 'Mohammad-Atikur-Rhaman',
			img: 'https://i.ibb.co/Pgx6Mgy/atik.png',
			title: 'Mohammad Atikur Rhaman',
			subtitle: 'Software Development Consultant',
		},
		{
			Id: 'Tarique-Mohammed-Nasrullah',
			img: 'https://i.ibb.co/kMhtBCh/Tarique.jpg',
			title: 'Ahmmed Sakib NomanTarique Mohammed Nasrullah',
			subtitle: 'Software Development Consultant',
		},
		{
			Id: 'Humaun-Kabir',
			img: 'https://i.ibb.co/BgJgx3z/Humaun.jpg',
			title: 'Humaun Kabir',
			subtitle: 'Apprentice Software Engineer',
		},
		{
			Id: 'Avik-Sarker',
			img: 'https://i.ibb.co/0QJk9NT/62066.jpg',
			title: 'Avik Sarker',
			subtitle: 'Apprentice Front-End Developer (Contractual)',
		},

		
	],
	pastEmployees: [
		{
			Id: 'Md. Shaffat Nur',
			img: 'https://i.ibb.co/CnyWfDv/Dipu.jpg',
			title: 'Md. Shaffat Nur',
			subtitle: 'Software Engineer Intern ( April 2019 - December 2019 )',
		},
		{
			Id: 'Md. Zobayer Mahmud Khan',
			img: 'https://i.ibb.co/L0dt9Bp/Zobayer.jpg',
			title: 'Md. Zobayer Mahmud Khan',
			subtitle: 'Software Engineer Intern ( April 2019 - December 2019 )',
		},
		{
			Id: 'Hasibul Haque Himel',
			img: 'https://i.ibb.co/qYRmm3H/Himel.jpg',
			title: 'Hasibul Haque Himel',
			subtitle: 'DevOps Intern (November 2019 - February 2020 )',
		},
		{
			Id: 'Md Fahim Shahriar',
			img: 'https://i.ibb.co/D5HnkwW/Shakkhor.jpg',
			title: 'Md Fahim Shahriar',
			subtitle: 'Apprentice Software Engineer ( March 2020 - February 2021 )',
		},
		{
			Id: 'Farhana Sharmin',
			img: 'https://i.ibb.co/T8h6SRT/Sharmin.jpg',
			title: 'Farhana Sharmin',
			subtitle: 'Operations and Development Intern ( April 2020 - January 2021 )',
		},
		{
			Id: 'Shahazadi Nowrin Hoque',
			img: 'https://i.ibb.co/5GbhkBy/Shahazadi.jpg',
			title: 'Shahazadi Nowrin Hoque',
			subtitle: 'Data Analysis Intern ( Sep 2020 - Jan 2021 )',
		},

		{
			Id: 'Humaun-Kabir',
			img: 'https://i.ibb.co/BgJgx3z/Humaun.jpg',
			title: 'Humaun Kabir',
			subtitle: 'Apprentice Software Engineer',
		}, 
		
		{
			Id: 'Muhiminul-Amin-Shafin',
			img: 'https://i.ibb.co/6yxTRwz/Shafin.jpg',
			title: 'Muhiminul Amin Shafin',
			subtitle: 'Product Design Intern ( June 2020 - October 2020 )',
		},
		{
			Id: 'Sumiya-Sayeed',
			img: 'https://i.ibb.co/Zfgmtzb/Sumiya.jpg',
			title: 'Sumiya Sayeed',
			subtitle: 'Junior Software Engineer (July 2018 - November 2022)',
		},


	],
}

export default tileData;