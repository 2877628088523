import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
	Grid,
	Paper,
	useTheme,
} from '@material-ui/core';
import data from '../assets/data/workflow';
import Banner from '../Banner/Banner';

const styles = theme => ({
	root: {
		margin: '20px auto',
		width: '100%',
		minHeight: '70vh',
		// marginBottom: 30,
		color: theme.palette.secondary.dark,
		[theme.breakpoints.up('xl')]: {
			minHeight: '40vh',
		},
	},
	paper: {
		height: 'auto',
		width: 300,
		minHeight: '350px',
		padding: theme.spacing(2),
		color: theme.palette.primary.dark,
		'&:hover': {
			transform: 'scale(1.1)',
			boxShadow: '0px 5px 3px -3px #b3e5fc'
		},
		transition: 'transform .5s ease-in-out',
	},
	control: {
		padding: theme.spacing(1),
	},
	icon: {
		width: '50px',
		height: '50px',
		margin: '20px auto'
	}
});

const Workflow = ({
	classes,
	id
}) => {
	const theme = useTheme();

	return (
		<ScrollAnimation
			animateIn="fadeInRight"
			duration={3}
			animateOnce={true}
		>
			<Grid
				container
				id={id}
				justify='center'
				alignItems='center'
				className={classes.root}
				spacing={2}
			>
				<Grid
					item
					md={12}
				>
					<Banner
						title='We work with rigor'
						variant='h4'
						flag='0'
					/>
				</Grid>
				<Grid
					item
					md={12}
					container
					justify="center"
					spacing={2}
				>
					{
						data.map((
							i, index
						) => (
							<Grid
								key={index}
								item
							>
								<Paper
									elevation={0}
									className={classes.paper}
								>
									<div align='center'>
										<img
											src={i.icon}
											alt={i.title}
											className={classes.icon}
										/>
									</div>
									<Banner
										title={i.title}
										variant='h6'
										flag='0'
										style={{
											color: theme.palette.secondary.dark
										}}
									/>
									<Banner
										title={i.des}
										variant='body1'
										flag='0'
									/>
								</Paper>
							</Grid>
						))
					}
				</Grid>
			</Grid>
		</ScrollAnimation>
	);
};

Workflow.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Workflow);
