import React from 'react';
import { withRouter, Link as Links } from 'react-router-dom'
import {
    MenuItem,
    AppBar,
    Toolbar,
    SvgIcon,
    IconButton,
    Button,
    Drawer,
} from '@material-ui/core';
import {
    Link,
    animateScroll as scroll
} from "react-scroll";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/icons/Menu';
import Logo from '../assets/logo';
import options from '../assets/data/menu';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    appbar: {
        background: 'transparent',
        boxShadow: 'none',
        backgroundColor: 'rgb( 102, 178, 255, 0.15)',
        backdropFilter: 'blur(3px)',
        overflow: 'hidden',
    },
    button: {
        // margin: theme.spacing.unit,
        color: theme.palette.secondary.dark,
        fontWeight: 900,
    },
    menu: {
        color: theme.palette.secondary.dark,
        padding: theme.spacing(2),
        width: '300px',
    },
    clear: {
        height: '30px',
        width: '100px',
        margin: '0 auto',
        color: theme.palette.secondary.dark,
    },
});
class AppBarForSmall extends React.Component {
    state = {
        anchorel: null,
    };

    scrollToTop = () => {
        scroll.scrollToTop();
    };

    handleClick = event => {
        this.setState({ anchorel: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorel: null });
    };

    render() {
        const { anchorel } = this.state;
        const { classes } = this.props;
        const open = Boolean(anchorel);
        const loc = this.props.location.pathname;

        return (
            <AppBar
                position="fixed"
                className={classes.appbar}
            >
                <Toolbar>
                    <div
                        className={classes.root}
                        onClick={this.scrollToTop}

                    >
                        {/* <IconButton 
                        // className={classes.button}
                        
                    > */}
                        <SvgIcon
                            component={loc === '/past-employees' ? Links : SvgIcon}
                            to={loc === '/past-employees' ? '/' : ''}
                            style={{
                                textDecoration: 'none'
                            }}
                        >
                            <Logo />
                        </SvgIcon>
                        {/* </IconButton> */}
                    </div>
                    <IconButton
                        className={classes.button}
                        aria-owns={open ? 'fade-menu' : null}
                        aria-haspopup="true"
                        onClick={this.handleClick}
                    >
                        <Menu />
                    </IconButton>
                    <Drawer
                        id="fade-menu"
                        className={classes.menu}
                        anchorel={anchorel}
                        open={open}
                        anchor='right'
                        transitionDuration={500}
                        onClose={this.handleClose}
                    // TransitionComponent={Fade}
                    >
                        {options.map(option => (
                            // <Link

                            // >
                            <MenuItem
                                className={classes.menu}
                                onClick={this.handleClose}
                                key={option.path}
                                // activeClass="active"
                                spy={loc === '/' ? true : null}
                                smooth={loc === '/' ? true : null}
                                offset={-70}
                                duration={loc === '/' ? 2000 : null}
                                component={loc === '/past-employees' ? Links : Link}
                                to={loc === '/past-employees' ? '/' : option.path}
                            >
                                {option.menu}
                            </MenuItem>
                            // </Link>
                        ))}
                        <Button
                            className={classes.clear}
                            onClick={this.handleClose}
                        >
                            Close
                        </Button>
                    </Drawer>
                </Toolbar>
            </AppBar>
        );
    }
}

AppBarForSmall.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(AppBarForSmall));
