import React, { Component } from 'react';
import { Map, Marker, TileLayer, Tooltip } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import InfoWindow from './InfoWindow';
import { Icon } from 'leaflet';

export const icon = new Icon({
	iconUrl: "https://i.ibb.co/0Db22t6/iconfinder-Facebook-UI-07-2344289.png",
	iconSize: [100, 100]
});

class App extends Component {

	render() {
		return (
			<div>
				<Map
					style={{ height: "480px", width: "100%" }}
					zoom={17}
					center={[23.739389, 90.377917]}
				>
					<TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

					<Marker
						position={[23.739389, 90.377917]}
						icon={icon}
						fill="red"
						color="red"
					>
						<Tooltip
							offset={[-8, -2]}
							opacity={1}
							direction='center'
						// style={{width: '100%', height: 'auto'}} 
						>
							<InfoWindow />
						</Tooltip>
					</Marker>
				</Map>
			</div>
		);
	}
}

export default App;