import React from 'react';
import {
	Link
} from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import compose from 'recompose/compose';
import {
	Button,
	GridList,
	GridListTile,
	GridListTileBar,
} from '@material-ui/core';
import Banner from '../Banner/Banner';

const styles = theme => ({
	root: {
		display: 'flex',
		//flexWrap: 'wrap',
		justifyContent: 'center',
		alignItems: 'flex-start',
		overflow: 'hidden',
		width: '100%',
		height: 'auto',
		minHeight: '90%',
		// margin: '0 auto',
		margin: '40px auto',
		backgroundColor: theme.palette.background.paper,
	},
	gridList: {
		width: '90%',
		height: '100%',
		margin: '0 auto',
		padding: theme.spacing(1),
		color: theme.palette.secondary.dark,
		[theme.breakpoints.only('xs')]: {
			width: '70%',
			padding: 0,
		},
		[theme.breakpoints.only('md')]: {
			width: '85%',
			padding: 0,
		},
		[theme.breakpoints.only('lg')]: {
			width: '75%',
		},
		[theme.breakpoints.only('sm')]: {
			width: '90%',
			padding: 0,
		},
		[theme.breakpoints.only('xl')]: {
			width: '70%',
		},
		'& .MuiGridListTileBar-titleWrap': {
			marginLeft: '6px',
			marginRight: '6px',
			marginBottom: '6px'
		},
		'& .MuiGridListTileBar-title': {
			whiteSpace: 'pre-wrap'
		},
		'& .MuiGridListTileBar-subtitle': {
			whiteSpace: 'pre-wrap'
		},
		'& .MuiGridListTileBar-rootSubtitle': {
			height: '80px',
			display: 'flex',
			alignItems: 'flex-end',
		},
		'& .MuiGridListTile-tile': {
			borderRadius: 8
		}
	},
	img: {
		width: '100%',
		height: '100%',
		position: 'absolute',
		objectFit: 'cover',
		// borderRadius: '4px',
	},
	title: {
		color: '#b0bec5',
		fontWeight: 900
	},
	titleBar: {
		background: 'linear-gradient(to top, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.5) 70%, rgba(0,0,0,0) 100%)',
	},
	button: {
		margin: theme.spacing(1),
		color: theme.palette.secondary.dark,
		fontWeight: 900,
	},
});

const col = {
	'xs': 1,
	'sm': 2,
	'md': 4,
	'lg': 4,
	'xl': 4,
};

class Team extends React.Component {
	render() {
		const {
			classes,
			id,
			data,
			title,
			flag2
		} = this.props;

		const colN = col[this.props.width];

		return (
			<>
				<ScrollAnimation
					animateIn="fadeInRight"
					duration={3}
					animateOnce={true}
				>
					<div
						className={classes.root}
						id={id}
					>

						<GridList
							cellHeight={this.props.width === 'xl' ? 450 : 280}
							className={classes.gridList}
							cols={colN}
							spacing={colN === 1 ? 5 : 20}
						>
							<GridListTile
								//key="Subheader" 
								cols={colN}
								style={{
									height: 'auto'
								}}
							>
								<Banner
									title={title}
									variant='h3'
									flag='0'
								/>
							</GridListTile>
							{data.map(tile => (
								<GridListTile
									key={tile.Id}
									className={classes.tile}
								>
									<img
										src={tile.img}
										position="static"
										alt={tile.title}
										className={classes.img}
									// height='100%' 
									// width='80%'
									/>
									<GridListTileBar
										title={tile.title}
										subtitle={tile.subtitle}
										classes={{
											root: classes.titleBar,
											title: classes.title,
										}}
									// actionIcon={
									//   <IconButton>
									//     <Link to={`/${tile.Id}`}>
									//       <ArrowDropUp className={classes.title} />
									//     </Link>
									//   </IconButton>
									// }
									/>
								</GridListTile>
							))}
						</GridList>

					</div>
					{
						flag2
							?
							(
								<div
									style={{
										width: '100%',
										marginBottom: 50,
										marginTop: 50,
									}}
									align='center'
								>
									<Button
										component={Link}
										variant='outlined'
										to='/past-employees'
										className={classes.button}
									>
										Past Employees
									</Button>
								</div>
							)
							:
							null
					}
				</ScrollAnimation>
			</>
		);
	}
}

Team.propTypes = {
	classes: PropTypes.object.isRequired,
	width: PropTypes.string,
};

export default compose(
	withStyles(styles), withWidth())(Team);
