import React, { Component } from 'react';
import {
	withRouter,
	Link as Links
} from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
	AppBar,
	Toolbar,
	Button,
	IconButton,
} from '@material-ui/core';
import {
	Link,
	animateScroll as scroll,
} from "react-scroll";
import Logo from '../assets/logo';
import options from '../assets/data/menu';

const styles = theme => ({
	root: {
		flexGrow: 1,
		padding: theme.spacing(1),
	},
	button: {
		margin: theme.spacing(1),
		color: theme.palette.secondary.dark,
		fontWeight: 900,
	},
	appbar: {
		background: 'transparent',
		backgroundColor: 'rgb( 102, 178, 255, 0.15)',
		backdropFilter: 'blur(3px)',
		boxShadow: 'none',
	}
});

class AppBarForBigger extends Component {
	scrollToTop = () => {
		scroll.scrollToTop();
	};

	render() {
		const { classes } = this.props;
		const loc = this.props.location.pathname;

		return (
			<AppBar
				position="fixed"
				className={classes.appbar}
			>
				<Toolbar>
					<div
						className={classes.root}
					>
						<IconButton
							onClick={this.scrollToTop}
							component={loc === '/past-employees' ? Links : IconButton}
							to={loc === '/past-employees' ? '/' : ''}
						>
							<Logo />
						</IconButton>
					</div>
					{
						options.map(
							(option, index) => (
								<Button
									size="small"
									key={index}
									activeClass="active"
									className={classes.button}
									onClick={loc === '/past-employees' ? this.scrollToTop : null}
									component={loc === '/past-employees' ? Links : Link}
									to={loc === '/past-employees' ? '/' : option.path}
									offset={-100}
									spy={loc === '/' ? true : null}
									smooth={loc === '/' ? true : null}
									duration={loc === '/' ? 1000 : null}
								>
									{option.menu}
								</Button>
							)
						)
					}
				</Toolbar>
			</AppBar>
		);
	}
}

AppBarForBigger.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(AppBarForBigger));
