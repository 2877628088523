import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import Carousel, { autoplayPlugin, slidesToShowPlugin } from '@brainhubeu/react-carousel';
import {
    makeStyles,
    IconButton,
    Avatar,
    Typography
} from '@material-ui/core';
import {
    KeyboardArrowLeft,
    KeyboardArrowRight,
    FormatQuote
} from '@material-ui/icons';
import '@brainhubeu/react-carousel/lib/style.css';
import Banner from '../Banner/Banner';
import data from '../assets/data/clients';

const useStyles = makeStyles(
    theme => (
        {
            gridList: {
                height: 'auto',
                width: '100%',
                margin: '30px auto',
                color: theme.palette.secondary.dark,
                '& .BrainhubCarousel__dots .BrainhubCarousel__dot::before': {
                    background: theme.palette.secondary.dark,
                    width: '12px',
                    height: '3px',
                    borderRadius: 0
                }
                //outline: 'solid',
            },
            container: {
                '& .MuiTypography-body1': {
                    lineHeight: 2
                },
                color: theme.palette.primary.dark,
                // width: '60%',
                // height: 'auto',
                // margin: '0 auto',
                // padding: theme.spacing(2),
                // textDecoration: 'none',
                // [theme.breakpoints.down('sm')]: {
                //     padding: 0,
                //     width: '90%',
                // },
            },
            grid3: {
                display: 'block',
                objectFit: 'cover',
                padding: 0,
                // filter: 'brightness(100%)',
                // '&:hover': {
                // 	filter: 'contrast(120%) ',
                // 	transform: 'scale(1.1)'
                // },
                // transition: 'transform 1s ease-in-out',
                marginBottom: 8,
            },
            carousel: {
                margin: '8px 8px 60px 108px',
                padding: '8px 8px 8px 8px',
                color: theme.palette.secondary.dark,
                [theme.breakpoints.down('sm')]: {
                    padding: '2px 2px 2px 2px',
                    margin: '8px 8px 60px 8px',
                },
            },
            carouselRight: {
                margin: '8px 108px 60px 8px',
                padding: '8px 8px 8px 8px',
                color: theme.palette.secondary.dark,
                [theme.breakpoints.down('sm')]: {
                    padding: '2px 2px 2px 2px',
                    margin: '8px 8px 60px 8px',
                },
            },
            img: {
                height: '50%',
                width: '15%',
                margin: theme.spacing(2),
                justifyContent: 'center',
                alignItems: 'strech',
                display: 'flex',
                [theme.breakpoints.down('sm')]: {
                    height: '40%',
                    width: '40%',
                },
                [theme.breakpoints.down('xs')]: {
                    height: '50%',
                    width: '50%',
                },
            },
            rotate: {
                transform: 'rotate(180deg)',
                color: theme.palette.secondary.dark,
            },
            notrotate: {
                transform: 'rotate(360deg)',
                color: theme.palette.secondary.dark,
            }
            // brainhub: {
            //     '& .BrainhubCarousel__dots .BrainhubCarousel__dot': {
            //         background: theme.palette.secondary.dark,
            //     }
            // }
        }
    )
);


const Clients = ({
    id
}) => {
    const classes = useStyles();

    return (
        <ScrollAnimation
            animateIn="fadeInRight"
            duration={3}
            animateOnce={true}
        >
            <div
                id={id}
                className={classes.gridList}
            >
                <Banner
                    title='Meet Our Valuable Clients'
                    variant='h4'
                    align='center'
                    color='inherit'
                    flag='0'
                />
                <br />
                <Carousel
                    slidesPerPage={1}
                    plugins={[
                        'clickToChange',
                        'infinite',
                        'centered',
                        {
                            resolve: autoplayPlugin,
                            options: {
                                interval: 2000,
                            }
                        },
                        {
                            resolve: slidesToShowPlugin,
                            options: {
                                numberOfSlides: 1
                            }
                        },
                    ]}
                    animationSpeed={1000}
                    addArrowClickHandler
                    arrowLeft={
                        <IconButton
                            className={classes.carousel}
                            style={{
                                fontSize: '2em'
                            }}
                            color='inherit'
                        >
                            <KeyboardArrowLeft fontSize='large' />
                        </IconButton>
                    }
                    arrowRight={
                        <IconButton
                            className={classes.carouselRight}
                            style={{
                                fontSize: '2em'
                            }}
                            color='inherit'
                        >
                            <KeyboardArrowRight fontSize='large' />
                        </IconButton>
                    }
                    infinite
                    dots
                // addArrowClickHandler
                // className={classes.brainhub}
                >
                    {
                        data.map(
                            (step, index) => (
                                <div
                                    key={index}
                                    align='center'
                                    className={classes.container}
                                >
                                    <Avatar
                                        alt="exception"
                                        src={step.imgPath}
                                        className={classes.img}
                                    />
                                    <Banner
                                        title={step.Name}
                                        variant='h6'
                                        flag='0'
                                    />
                                    <Banner
                                        title={step.des}
                                        variant='body1'
                                        flag='0'
                                        style={{
                                            lineHeight: 2
                                        }}
                                    />
                                    <Typography
                                        variant='body1'
                                        gutterBottom
                                    >
                                        <FormatQuote className={classes.rotate} />
                                        {step.detail}
                                        <FormatQuote className={classes.notrotate} />
                                    </Typography>
                                </div>
                            )
                        )
                    }
                </Carousel>
            </div>
        </ScrollAnimation>
    );
}

export default Clients;

