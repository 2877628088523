import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import Copyright from '@material-ui/icons/Copyright';
import {
	SvgIcon,
	Grid,
	Typography,
	IconButton,
	withWidth,
} from '@material-ui/core';
import logos from './data';

const styles = theme => ({
	root: {
		height: 'auto',
		width: '100%',
		margin: '0 auto',
		backgroundColor: theme.palette.primary.dark,
		color: 'white',
	},
	paper1: {
		width: '100%',
		padding: theme.spacing(1),
		color: 'white',
	},
	icon: {
		fontSize: '20px',
		color: 'white',
		display: 'inline-flex',
		verticalAlign: 'middle',
		position: 'static',
		filter: 'opacity(.6)',
		'&:hover, &:active': {
			color: '#ffffff',
			fontWeight: '900',
			filter: 'opacity(1)',
		},
	},
});

const Footerlast = ({
	classes,
}) => {
	var d = new Date();

	return (
		<Grid
			container
			direction="row"
			justify="center"
			alignItems="center"
			spacing={2}
			className={classes.root}
		>
			<Grid
				item
				xs={6}
			>
				<Typography
					variant="caption"
					color="inherit"
					align="left"
					className={classes.paper1}
				>
					<Copyright className={classes.icon} />
					2018-{d.getFullYear()} Aubichol Intelligent Technologies. All rights reserved.
				</Typography>
			</Grid>
			<Grid
				item
				xs={6}
			>
				<Typography
					color="inherit"
					align="right"
					className={classes.paper1}
				>
					{
						logos.map((
							i,
							index
						) => (
							<IconButton
								key={index}
								href={i.href}
							>
								<SvgIcon className={classes.icon}>
									<svg
										xmlns={i.xmlns}
										viewBox={i.viewbox}
									>
										<path d={i.d} />
									</svg>
								</SvgIcon>
							</IconButton>
						))
					}
				</Typography>
			</Grid>
		</Grid>
	)
};

Footerlast.propTypes = {
	classes: PropTypes.object.isRequired,
	width: PropTypes.string,
};

export default compose(
	withStyles(styles),
	withWidth(),
)(Footerlast);
