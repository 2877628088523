const options = [
    {
        menu: 'HOME',
        path: 'home',
    },
    {
        menu: 'TEAM',
        path: 'team',
    },
    {
        menu: 'SERVICES',
        path: 'services',
    },
    {
        menu: 'WORKFLOW',
        path: 'workflow',
    },
    {
        menu: 'PRODUCTS',
        path: 'product',
    },
    {
        menu: 'CLIENTS',
        path: 'clients',
    },
    {
        menu: 'CONTACT US',
        path: 'contact',
    },
];

export default options;
