import React from 'react';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import AppBarForSmall from './AppBarForSmall';
import AppBarForBigger from './AppBarForBigger';

class Appbar extends React.Component {
	render() {
		if (isWidthUp('md', this.props.width)) {
			return <AppBarForBigger />
		}

		return <AppBarForSmall />;
	}
}

export default withWidth()(Appbar);