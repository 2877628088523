import React from 'react';
import {
	Typography,
	Grid,
	withStyles
} from '@material-ui/core';
import aubichol from '../assets/ContactUs/aubichol.jpg';

const styles = theme => ({
	card: {
		width: '100%',
		height: 'auto',
		margin: '0 auto',
		padding: 3,
		[theme.breakpoints.down('sm')]: {
			minHeight: 200,
		},
	},
	media: {
		height: 100,
		width: 100,
		// paddingTop: '10%',
		margin: '0 auto'
	},
	content: {
		width: '100%',
		height: 'auto'
	}
});

const InfoWindow = ({
	classes,
}) => (
	<Grid 
		container
  		direction="column"
  		wrap="nowrap"
  		justify="center"
		alignItems="center" 
		className={classes.card}
	>
	  	<img
			className={classes.media}
			src={aubichol}
			title="Aubichol Intelligent Technologies"
			alt="Aubichol Intelligent Technologies"
		/>
		<Grid 
			item 
			xs={12}
		>
			<Typography
				// component="p" 
				gutterBottom 
				variant="h6"
				align='center'  
			>
				Aubichol Intelligent Technologies
			</Typography>
			<Typography 
				variant='body2'
				// component="p"
				align='center' 
			>
				Aubichol Intelligent Technologies is at House 37, Road 3, Dhanmondi R/A
			</Typography>
		</Grid>
	</Grid>
);

export default withStyles(styles)(InfoWindow);