import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import styled, { keyframes } from 'styled-components';
// import { slideInDown } from 'react-animations';
import Banner from '../Banner/Banner';

const styles = theme => ({
	background: {
		color: theme.palette.secondary.dark,
		// boxShadow: '3 3px 5px 2px rgba(255, 105, 135, .3)',
		alignItems: 'center',
		justifyContent: 'center',
		width: '100%',
		height: '100vh',
		overflowX: 'hidden',
		[theme.breakpoints.down('md')]: {
			backgroundImage: 'linear-gradient(90deg, #e1f5fe 30%, #b3e5fc 70%)'
		},
		// backgroundColor: theme.palette.secondary.main,
	},
	div: {
		position: 'relative',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		height: '100vh',
		width: '100%',
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'center',
		justifyContent: 'center',
		color: theme.palette.secondary.dark,
	}
})

const slide = keyframes`
	0% {
		transform: translateX(-25%);
	}
	100% {
		transform: translateX(25%);
  	}
`;

// const SlideInDown = styled.div`animation: 5s ${keyframes`${slideInDown}`} 1`;

const Bg1 = styled.div`
	animation:${slide} 7s ease-in-out infinite alternate-reverse;
	background-image: linear-gradient(-70deg, #b3e0f5 20%, #09f 100%);
	bottom: 0;
	left: -50%;
	opacity: .5;
	margin-left: auto;
	margin-right: auto;
	position: absolute;
	overflow-x: hidden;
	right: -50%;
	top: 0;
	z-index: -1;
`

const Bg2 = styled.div`
	animation-direction: alternate-reverse;
	animation-duration: 5s;
	overflow-x: hidden;
`

const Bg3 = styled.div`
	animation-duration: 7s;
	overflow-x: hidden;
`

const Home = ({
	classes,
	id
}) => (
	<div
		style={{
			height: '100vh',
			maxWidth: '100%',
			overflowX: 'hidden'
		}}
		id={id}
	>
		<Hidden mdDown>
			<Bg1 />
			<Bg2 />
			<Bg3 />
		</Hidden>

		<Grid
			container
			className={classes.background}
		>
			<ScrollAnimation
				animateIn="fadeInDown"
				duration={3}
				animateOnce={true}
			>
				<Grid
					item
					md={12}
					xs={12}
					lg={12}
				>
					{/* <SlideInDown> */}

					<Banner
						title='Welcome to Aubichol Intelligent Technologies'
						variant="h3"
						flag='0'
					/>

					<Banner
						title='We mean each line of code'
						variant="h6"
						flag='0'
					/>
					{/* </SlideInDown> */}
				</Grid>
			</ScrollAnimation>

		</Grid>

	</div>
);

Home.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Home);
