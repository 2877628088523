import React from 'react';

export default () => <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
width="40px" height="44px" viewBox="112.189 275.559 354.704 290.772"
enableBackground="new 112.189 275.559 354.704 290.772" xmlSpace="preserve">
<g>
<rect x="112.189" y="275.559" fill="#231F20" width="354.704" height="226.675"/>
<g>
   <path fill="#FFFFFF" stroke="#FFFFFF" strokeMiterlimit="10" d="M461.853,410.685c-0.004-3.245-0.313-6.484-0.543-9.738
	   c-0.688-6.485-1.751-12.967-3.342-19.358c-1.647-6.375-3.732-12.661-6.263-18.795c-2.61-6.098-5.657-12.024-9.116-17.725
	   c-7.008-11.336-15.687-21.734-25.82-30.496c-2.486-2.246-5.158-4.272-7.799-6.33l-4.085-2.92c-1.36-0.977-2.8-1.833-4.196-2.755
	   c-0.707-0.447-1.4-0.919-2.118-1.347l-2.173-1.255c-1.456-0.822-2.873-1.716-4.377-2.449l-4.459-2.29l-4.561-2.074
	   c-6.131-2.636-12.42-4.893-18.848-6.592c-6.436-1.653-12.957-2.908-19.521-3.629c-1.642-0.188-3.302-0.275-4.947-0.418
	   l-2.473-0.188l-2.444-0.067c-1.645-0.029-3.22-0.124-4.913-0.101c-1.634,0.038-3.267,0.075-4.896,0.112
	   c-1.629,0.037-3.258,0.075-4.884,0.112c-1.624,0.072-3.242,0.226-4.859,0.334c-3.234,0.254-6.47,0.408-9.663,0.879
	   c-12.813,1.474-25.367,4.01-37.357,7.706c-24.003,7.344-45.826,18.684-64.347,32.333c-9.235,6.867-17.668,14.271-25.299,21.953
	   c-1.873,1.956-3.729,3.895-5.57,5.816c-1.749,2.007-3.481,3.995-5.2,5.964c-1.759,1.936-3.3,4.02-4.903,6.02
	   c-1.583,2.016-3.196,3.978-4.616,6.053c-1.46,2.045-2.905,4.067-4.335,6.068c-1.334,2.066-2.653,4.11-3.958,6.13
	   c-0.644,1.017-1.318,2.004-1.923,3.027c-0.595,1.029-1.187,2.052-1.773,3.068c-1.162,2.041-2.366,4.025-3.453,6.042
	   c-1.049,2.036-2.084,4.047-3.105,6.029c-0.501,0.997-1.038,1.968-1.501,2.966c-0.455,1.002-0.908,1.996-1.357,2.983
	   c-0.897,1.976-1.784,3.923-2.656,5.841c-0.779,1.957-1.549,3.885-2.306,5.782c-5.988,15.214-9.421,29.125-11.429,40.675
	   c-0.262,1.445-0.517,2.85-0.764,4.217c-0.178,1.375-0.352,2.711-0.52,4.008c-0.321,2.596-0.68,5.018-0.911,7.287
	   c-0.178,2.283-0.344,4.396-0.495,6.33c-0.13,1.934-0.342,3.707-0.343,5.254c-0.172,6.23-0.264,9.555-0.264,9.555l3.971,0.1
	   c0,0,0.076-3.322,0.219-9.553c-0.005-1.57,0.196-3.258,0.314-5.148c0.139-1.883,0.29-3.938,0.454-6.16
	   c0.215-2.223,0.558-4.607,0.86-7.154c0.158-1.273,0.322-2.588,0.49-3.939c0.236-1.342,0.48-2.723,0.73-4.143
	   c1.919-11.355,5.227-25.035,11.043-40.017c0.733-1.867,1.48-3.768,2.238-5.694c0.85-1.891,1.711-3.809,2.585-5.754
	   c0.438-0.974,0.879-1.953,1.321-2.94c0.449-0.984,0.975-1.939,1.463-2.923c0.995-1.956,2.005-3.938,3.027-5.945
	   c1.06-1.988,2.235-3.946,3.369-5.958c0.573-1.004,1.149-2.013,1.729-3.027c0.589-1.011,1.251-1.984,1.878-2.987
	   c1.273-1.994,2.563-4.012,3.865-6.051c1.398-1.977,2.813-3.974,4.24-5.992c1.387-2.05,2.968-3.987,4.517-5.979
	   c1.57-1.977,3.077-4.036,4.8-5.949c1.684-1.948,3.382-3.913,5.095-5.896c1.805-1.901,3.625-3.819,5.461-5.755
	   c7.479-7.603,15.756-14.937,24.827-21.749c18.191-13.539,39.664-24.825,63.323-32.188c11.818-3.704,24.203-6.266,36.857-7.781
	   c3.153-0.48,6.351-0.646,9.546-0.913c1.599-0.114,3.197-0.273,4.802-0.353c1.608-0.045,3.217-0.089,4.829-0.133
	   c1.61-0.045,3.224-0.09,4.84-0.135c1.555-0.033,3.231,0.056,4.845,0.077l2.444,0.057l2.409,0.174
	   c1.604,0.132,3.214,0.206,4.822,0.386c6.45,0.677,12.862,1.88,19.194,3.474c6.325,1.642,12.517,3.831,18.56,6.394l4.494,2.02
	   l4.396,2.233c1.483,0.714,2.88,1.586,4.317,2.388l2.145,1.227c0.709,0.418,1.394,0.878,2.09,1.315
	   c1.377,0.902,2.802,1.737,4.144,2.692l4.037,2.855c2.606,2.014,5.248,3.996,7.707,6.197c10.024,8.584,18.627,18.794,25.592,29.941
	   c3.438,5.605,6.469,11.441,9.076,17.446c2.522,6.041,4.61,12.237,6.268,18.526c1.601,6.305,2.682,12.703,3.392,19.11
	   c0.241,3.215,0.563,6.417,0.582,9.626c0.03,1.622,0.131,3.18,0.057,4.794l-0.057,2.409c-0.036,0.798-0.109,1.581-0.164,2.372
	   c-0.896,12.676-4.989,24.934-11.396,35.497c-3.234,5.27-7.037,10.129-11.281,14.479c-4.238,4.355-8.979,8.133-13.981,11.359
	   c-5.017,3.213-10.319,5.867-15.797,7.85c-5.479,1.982-11.104,3.346-16.731,4.098c-5.639,0.746-11.246,0.947-16.749,0.326
	   c-5.446-0.578-10.713-2.268-15.494-4.582l-1.801-0.861c-0.582-0.318-1.139-0.68-1.708-1.016l-1.693-1.025l-0.843-0.512
	   l-0.79-0.588c-1.047-0.787-2.107-1.551-3.131-2.35c-0.964-0.873-1.923-1.74-2.878-2.604c-3.677-3.611-6.734-7.688-9.153-12.012
	   c-2.327-4.385-3.926-8.996-4.967-13.627c-0.323-2.348-0.846-4.648-0.859-6.975c-0.036-1.152-0.178-2.316-0.129-3.441
	   c0.028-0.572,0.056-1.146,0.083-1.717l0.041-0.854l0.123-0.836c0.566-4.453,2.122-8.682,4.38-12.301
	   c2.259-3.629,5.209-6.67,8.513-8.97c3.303-2.306,6.956-3.867,10.625-4.692c0.438-0.132,0.883-0.209,1.328-0.271
	   c0-0.014-0.005-0.025-0.005-0.04c0-0.093,0.022-0.181,0.028-0.271c-0.01,0.001-0.02,0.002-0.029,0.004
	   c-0.468,0.061-0.934,0.14-1.392,0.272c-3.711,0.818-7.412,2.379-10.767,4.696c-3.353,2.313-6.356,5.377-8.661,9.045
	   c-2.31,3.668-3.906,7.924-4.507,12.482l-0.127,0.854l-0.046,0.855c-0.029,0.57-0.061,1.143-0.092,1.717
	   c-0.055,1.176,0.081,2.324,0.113,3.498c0.004,2.35,0.519,4.676,0.837,7.053c1.029,4.688,2.625,9.363,4.961,13.813
	   c2.429,4.391,5.508,8.535,9.218,12.213c0.964,0.881,1.934,1.766,2.906,2.652c1.033,0.816,2.105,1.596,3.165,2.398l0.799,0.6
	   l0.854,0.525l1.713,1.047c0.576,0.344,1.141,0.713,1.729,1.039l1.823,0.883c4.858,2.377,10.156,4.111,15.759,4.734l1.043,0.16
	   c0.349,0.037,0.694,0.043,1.043,0.064l2.091,0.109l2.118,0.107l2.12-0.049l2.127-0.055l2.129-0.156
	   c0.711-0.059,1.426-0.092,2.137-0.172l2.134-0.266c5.697-0.732,11.396-2.084,16.952-4.066c5.557-1.98,10.941-4.645,16.04-7.879
	   c5.086-3.246,9.911-7.053,14.229-11.451c4.328-4.391,8.211-9.307,11.521-14.643c6.559-10.705,10.782-23.129,11.756-36.06
	   c0.059-0.809,0.138-1.624,0.178-2.43l0.067-2.409C461.968,413.952,461.878,412.288,461.853,410.685z"/>
   <polygon fill="#FFFFFF" points="150.685,494.125 151.342,484.218 153.863,484.38 153.241,494.289 		"/>
   
	   <rect x="152.26" y="481.532" transform="matrix(0.0974 -0.9952 0.9952 0.0974 -342.6138 587.8171)" fill="#FFFFFF" width="0.993" height="2.52"/>
   
	   <rect x="153.58" y="470.738" transform="matrix(0.1478 -0.989 0.989 0.1478 -335.4887 554.6229)" fill="#FFFFFF" width="0.991" height="2.481"/>
   <polygon fill="#FFFFFF" points="154.064,464.382 156.101,454.667 158.473,455.16 156.471,464.882 		"/>
   
	   <rect x="157.127" y="452.254" transform="matrix(0.2342 -0.9722 0.9722 0.2342 -320.1429 500.5047)" fill="#FFFFFF" width="0.992" height="2.416"/>
   
	   <rect x="159.936" y="441.705" transform="matrix(0.2845 -0.9587 0.9587 0.2845 -309.8013 470.7145)" fill="#FFFFFF" width="0.992" height="2.378"/>
   <polygon fill="#FFFFFF" points="161.565,435.412 164.938,426.074 167.12,426.857 163.779,436.208 		"/>
   
	   <rect x="166.05" y="423.912" transform="matrix(0.3677 -0.93 0.93 0.3677 -289.9821 423.6697)" fill="#FFFFFF" width="0.993" height="2.311"/>
   
	   <rect x="170.326" y="413.871" transform="matrix(0.4151 -0.9098 0.9098 0.4151 -277.6507 398.1669)" fill="#FFFFFF" width="0.993" height="2.274"/>
   <polygon fill="#FFFFFF" points="173.036,407.777 177.684,399.002 179.642,400.035 175.025,408.826 		"/>
   
	   <rect x="178.882" y="397.122" transform="matrix(0.4957 -0.8685 0.8685 0.4957 -255.3946 356.6066)" fill="#FFFFFF" width="0.993" height="2.207"/>
   
	   <rect x="184.539" y="387.756" transform="matrix(0.5385 -0.8426 0.8426 0.5385 -242.255 335.3821)" fill="#FFFFFF" width="0.992" height="2.169"/>
   <polygon fill="#FFFFFF" points="188.267,382.029 194.103,373.996 195.812,375.232 190.003,383.287 		"/>
   
	   <rect x="195.35" y="372.377" transform="matrix(0.6124 -0.7906 0.7906 0.6124 -219.3065 299.5812)" fill="#FFFFFF" width="0.992" height="2.103"/>
   
	   <rect x="202.258" y="363.928" transform="matrix(0.6525 -0.7578 0.7578 0.6525 -206.1064 280.4733)" fill="#FFFFFF" width="0.992" height="2.065"/>
   <polygon fill="#FFFFFF" points="206.972,358.692 213.883,351.567 215.325,352.961 208.438,360.11 		"/>
   
	   <rect x="215.178" y="350.234" transform="matrix(0.7181 -0.6959 0.6959 0.7181 -183.6384 249.1074)" fill="#FFFFFF" width="0.991" height="1.998"/>
   
	   <rect x="223.217" y="342.842" transform="matrix(0.7532 -0.6577 0.6577 0.7532 -170.9431 231.9833)" fill="#FFFFFF" width="0.992" height="1.96"/>
   <polygon fill="#FFFFFF" points="228.781,338.242 236.631,332.162 237.798,333.663 229.969,339.771 		"/>
   
	   <rect x="237.923" y="331.076" transform="matrix(0.8089 -0.588 0.588 0.8089 -149.6532 203.6372)" fill="#FFFFFF" width="0.992" height="1.894"/>
   
	   <rect x="246.895" y="324.879" transform="matrix(0.8377 -0.5461 0.5461 0.8377 -137.7695 187.9534)" fill="#FFFFFF" width="0.994" height="1.856"/>
   <path fill="#FFFFFF" d="M253.24,321.068c0,0,0.533-0.314,1.337-0.787c0.399-0.235,0.868-0.511,1.369-0.807
	   c0.251-0.147,0.511-0.3,0.773-0.455l0.423-0.245l0.407-0.225c2.178-1.192,4.354-2.385,4.354-2.385l0.866,1.573
	   c0,0-2.174,1.201-4.347,2.401l-0.406,0.225l-0.374,0.22c-0.263,0.157-0.521,0.311-0.771,0.457
	   c-0.501,0.298-0.969,0.575-1.368,0.813c-0.801,0.475-1.334,0.791-1.334,0.791L253.24,321.068z"/>
   
	   <rect x="263.161" y="315.355" transform="matrix(0.8921 -0.4519 0.4519 0.8921 -114.4603 153.2903)" fill="#FFFFFF" width="0.994" height="1.79"/>
   
	   <rect x="273.094" y="310.871" transform="matrix(0.927 -0.3751 0.3751 0.927 -96.9621 125.3915)" fill="#FFFFFF" width="0.994" height="1.751"/>
   <polygon fill="#FFFFFF" points="280.318,308.276 289.831,305.431 290.318,307.052 280.816,309.931 		"/>
   
	   <rect x="291.021" y="305.028" transform="matrix(0.9713 -0.2377 0.2377 0.9713 -64.343 78.0491)" fill="#FFFFFF" width="0.992" height="1.685"/>
   
	   <rect x="301.742" y="302.885" transform="matrix(0.9877 -0.1561 0.1561 0.9877 -43.7075 50.9075)" fill="#FFFFFF" width="0.995" height="1.648"/>
   <polygon fill="#FFFFFF" points="309.559,301.929 319.47,301.325 319.568,302.909 309.659,303.55 		"/>
   
	   <rect x="320.521" y="301.308" transform="matrix(0.9999 -0.0118 0.0118 0.9999 -3.5454 3.8123)" fill="#FFFFFF" width="0.992" height="1.582"/>
   
	   <rect x="331.432" y="301.638" transform="matrix(0.9974 0.0717 -0.0717 0.9974 22.5488 -23.0321)" fill="#FFFFFF" width="0.994" height="1.543"/>
   <polygon fill="#FFFFFF" points="339.456,302.388 349.245,304.036 349.002,305.501 339.207,303.886 		"/>
   
	   <rect x="350.088" y="304.36" transform="matrix(0.9767 0.2144 -0.2144 0.9767 73.5771 -68.0791)" fill="#FFFFFF" width="0.993" height="1.477"/>
   
	   <rect x="360.667" y="307.13" transform="matrix(0.9554 0.2952 -0.2952 0.9554 106.9685 -92.8942)" fill="#FFFFFF" width="0.992" height="1.438"/>
   <polygon fill="#FFFFFF" points="368.469,309.566 377.64,313.375 377.112,314.65 367.929,310.875 		"/>
   
	   <rect x="378.216" y="313.952" transform="matrix(0.9037 0.4282 -0.4282 0.9037 171.2108 -131.8629)" fill="#FFFFFF" width="0.992" height="1.373"/>
   
	   <rect x="387.911" y="319.037" transform="matrix(0.865 0.5019 -0.5019 0.865 212.8963 -151.7477)" fill="#FFFFFF" width="0.992" height="1.334"/>
   <polygon fill="#FFFFFF" points="395.112,323.088 403.185,328.871 402.443,329.908 394.351,324.154 		"/>
   
	   <rect x="403.487" y="329.665" transform="matrix(0.7825 0.6226 -0.6226 0.7825 293.4893 -179.6917)" fill="#FFFFFF" width="0.992" height="1.269"/>
   
	   <rect x="411.757" y="336.853" transform="matrix(0.7279 0.6856 -0.6856 0.7279 343.5394 -190.8464)" fill="#FFFFFF" width="0.993" height="1.23"/>
   <polygon fill="#FFFFFF" points="417.983,342.288 424.526,349.755 423.648,350.528 417.077,343.084 		"/>
   
	   <rect x="424.512" y="350.742" transform="matrix(0.6212 0.7836 -0.7836 0.6212 436.304 -199.9765)" fill="#FFFFFF" width="0.992" height="1.164"/>
   
	   <rect x="430.941" y="359.555" transform="matrix(0.5536 0.8328 -0.8328 0.5536 492.517 -198.5348)" fill="#FFFFFF" width="0.993" height="1.125"/>
   <path fill="#FFFFFF" d="M435.888,366.159c0,0,0.079,0.134,0.215,0.368c0.068,0.118,0.151,0.26,0.246,0.424
	   c0.094,0.17,0.201,0.362,0.315,0.571c0.443,0.819,1.033,1.911,1.622,3.004c0.309,0.538,0.56,1.117,0.813,1.646
	   c0.252,0.531,0.485,1.027,0.688,1.452c0.4,0.851,0.668,1.418,0.668,1.418l-0.977,0.432c0,0-0.264-0.556-0.66-1.388
	   c-0.198-0.416-0.43-0.902-0.678-1.422c-0.252-0.519-0.495-1.08-0.809-1.621c-0.593-1.091-1.187-2.182-1.632-3
	   c-0.11-0.197-0.213-0.379-0.302-0.539c-0.096-0.164-0.179-0.307-0.247-0.423c-0.138-0.234-0.217-0.368-0.217-0.368
	   L435.888,366.159z"/>
   
	   <rect x="440.073" y="376.12" transform="matrix(0.3851 0.9229 -0.9229 0.3851 618.5258 -174.9832)" fill="#FFFFFF" width="0.994" height="1.059"/>
   
	   <rect x="443.563" y="386.454" transform="matrix(0.2569 0.9664 -0.9664 0.2569 703.9759 -141.5929)" fill="#FFFFFF" width="0.992" height="1.022"/>
   <path fill="#FFFFFF" d="M446.177,394.164c0,0,0.337,2.459,0.672,4.918c0.187,2.498,0.373,4.996,0.373,4.996l-0.961,0.044
	   c0,0-0.191-2.452-0.381-4.905c-0.347-2.458-0.691-4.916-0.691-4.916L446.177,394.164z"/>
   
	   <rect x="446.3" y="405.091" transform="matrix(0.0256 0.9997 -0.9997 0.0256 840.8147 -51.4465)" fill="#FFFFFF" width="0.993" height="0.956"/>
   
	   <rect x="445.853" y="416.049" transform="matrix(-0.1111 0.9938 -0.9938 -0.1111 909.8602 19.1853)" fill="#FFFFFF" width="0.993" height="0.917"/>
   <path fill="#FFFFFF" d="M445.634,423.951c0,0-0.572,2.416-1.146,4.832c-0.725,2.395-1.449,4.791-1.449,4.791l-0.813-0.273
	   c0,0,0.705-2.357,1.41-4.719c0.563-2.418,1.129-4.836,1.129-4.836L445.634,423.951z"/>
   
	   <rect x="441.651" y="434.398" transform="matrix(-0.3362 0.9418 -0.9418 -0.3362 1000.3134 164.6084)" fill="#FFFFFF" width="0.993" height="0.852"/>
   
	   <rect x="437.277" y="444.455" transform="matrix(-0.4605 0.8876 -0.8876 -0.4605 1034.2617 261.1577)" fill="#FFFFFF" width="0.992" height="0.812"/>
   <path fill="#FFFFFF" d="M434.428,451.535c0,0-1.403,2.049-2.809,4.096c-1.537,1.971-3.073,3.943-3.073,3.943l-0.582-0.482
	   c0,0,1.511-1.947,3.021-3.895c1.396-2.055,2.792-4.105,2.792-4.105L434.428,451.535z"/>
   
	   <rect x="426.812" y="460.121" transform="matrix(-0.6531 0.7573 -0.7573 -0.6531 1055.1017 437.619)" fill="#FFFFFF" width="0.993" height="0.747"/>
   
	   <rect x="419.117" y="467.891" transform="matrix(-0.75 0.6614 -0.6614 -0.75 1044.0378 541.8911)" fill="#FFFFFF" width="0.992" height="0.708"/>
   <path fill="#FFFFFF" d="M414.026,473.191c0,0-0.512,0.352-1.279,0.879c-0.383,0.264-0.829,0.57-1.31,0.902
	   c-0.483,0.324-0.979,0.695-1.521,1.01c-1.065,0.65-2.131,1.303-2.931,1.793c-0.806,0.488-1.351,0.775-1.351,0.775l-0.318-0.566
	   c0,0,0.554-0.293,1.336-0.775c0.789-0.484,1.839-1.133,2.89-1.779c0.532-0.309,1.03-0.684,1.512-1.012
	   c0.479-0.332,0.925-0.641,1.309-0.906c0.766-0.529,1.274-0.883,1.274-0.883L414.026,473.191z"/>
   
	   <rect x="403.672" y="478.672" transform="matrix(-0.8919 0.4523 -0.4523 -0.8919 981.2858 723.3721)" fill="#FFFFFF" width="0.993" height="0.642"/>
   
	   <rect x="393.492" y="482.548" transform="matrix(-0.9683 0.2497 -0.2497 -0.9683 896.0652 852.0262)" fill="#FFFFFF" width="0.993" height="0.605"/>
   <path fill="#FFFFFF" d="M386.653,484.447c0,0-2.479,0.129-4.957,0.254c-2.5-0.119-5-0.24-5-0.24l0.052-0.543
	   c0,0,2.46,0.111,4.92,0.223c2.478-0.137,4.955-0.271,4.955-0.271L386.653,484.447z"/>
   
	   <rect x="374.757" y="483.758" transform="matrix(-0.9918 -0.1279 0.1279 -0.9918 685.5127 1012.0761)" fill="#FFFFFF" width="0.993" height="0.538"/>
   
	   <rect x="364.123" y="481.223" transform="matrix(-0.9409 -0.3386 0.3386 -0.9409 544.6863 1057.9622)" fill="#FFFFFF" width="0.992" height="0.5"/>
   <path fill="#FFFFFF" d="M357.723,478.669c0,0-2.124-1.285-4.248-2.57c-1.999-1.498-3.999-3-3.999-3l0.281-0.338
	   c0,0,1.978,1.473,3.955,2.947c2.127,1.275,4.256,2.555,4.256,2.555L357.723,478.669z"/>
   
	   <rect x="347.972" y="471.741" transform="matrix(-0.749 -0.6625 0.6625 -0.749 296.796 1056.3428)" fill="#FFFFFF" width="0.993" height="0.435"/>
   
	   <rect x="340.654" y="463.677" transform="matrix(-0.587 -0.8096 0.8096 -0.587 165.8585 1012.3595)" fill="#FFFFFF" width="0.992" height="0.395"/>
   <path fill="#FFFFFF" d="M337.084,457.632c0,0-0.25-0.57-0.623-1.424c-0.094-0.211-0.193-0.443-0.302-0.688
	   c-0.115-0.238-0.189-0.518-0.292-0.793c-0.188-0.555-0.394-1.146-0.597-1.738c-0.155-0.605-0.312-1.209-0.458-1.775
	   c-0.161-0.563-0.249-1.104-0.325-1.564c-0.157-0.924-0.263-1.539-0.263-1.539l0.334-0.043c0,0,0.105,0.607,0.266,1.52
	   c0.075,0.457,0.165,0.99,0.327,1.547c0.146,0.561,0.302,1.156,0.458,1.754c0.202,0.582,0.403,1.162,0.593,1.707
	   c0.101,0.271,0.173,0.541,0.29,0.781c0.107,0.244,0.209,0.475,0.305,0.688c0.376,0.852,0.626,1.42,0.626,1.42L337.084,457.632z"/>
   
	   <rect x="333.749" y="446.441" transform="matrix(-0.0809 -0.9967 0.9967 -0.0809 -83.8503 815.8934)" fill="#FFFFFF" width="0.99" height="0.329"/>
   
	   <rect x="334.766" y="435.632" transform="matrix(0.2698 -0.9629 0.9629 0.2698 -174.8046 641.0464)" fill="#FFFFFF" width="0.992" height="0.291"/>
   <path fill="#FFFFFF" d="M337.972,428.857c0,0,0.326-0.527,0.813-1.32c0.242-0.396,0.522-0.861,0.884-1.324
	   c0.347-0.473,0.717-0.975,1.087-1.477c0.406-0.473,0.814-0.941,1.196-1.385c0.196-0.215,0.365-0.443,0.563-0.623
	   c0.19-0.188,0.373-0.365,0.54-0.527c0.667-0.654,1.11-1.089,1.11-1.089l0.155,0.171c0,0-0.438,0.434-1.1,1.086
	   c-0.164,0.162-0.343,0.338-0.532,0.523c-0.196,0.18-0.362,0.408-0.557,0.621c-0.378,0.439-0.78,0.908-1.185,1.377
	   c-0.364,0.498-0.728,0.994-1.067,1.463c-0.354,0.455-0.634,0.922-0.873,1.32c-0.486,0.793-0.811,1.322-0.811,1.322
	   L337.972,428.857z"/>
   
	   <rect x="344.88" y="420.122" transform="matrix(0.7739 -0.6333 0.6333 0.7739 -188.0465 313.7333)" fill="#FFFFFF" width="0.993" height="0.227"/>
   
	   <rect x="354.507" y="415.137" transform="matrix(0.9767 -0.2144 0.2144 0.9767 -80.7802 85.7819)" fill="#FFFFFF" width="0.993" height="0.187"/>
   <path fill="#FFFFFF" d="M362.395,414.982c0,0,0.603,0.146,1.506,0.367c0.228,0.057,0.472,0.114,0.73,0.18
	   c0.261,0.054,0.517,0.186,0.792,0.281c0.545,0.213,1.127,0.438,1.708,0.664c0.547,0.299,1.094,0.597,1.605,0.874
	   c0.25,0.15,0.52,0.253,0.727,0.426c0.215,0.156,0.42,0.306,0.608,0.442c0.754,0.55,1.258,0.918,1.258,0.918l-0.083,0.099
	   c0,0-0.5-0.362-1.252-0.905c-0.188-0.136-0.392-0.283-0.604-0.439c-0.207-0.171-0.475-0.271-0.722-0.419
	   c-0.512-0.274-1.056-0.568-1.599-0.861c-0.576-0.223-1.15-0.443-1.69-0.65c-0.273-0.095-0.523-0.224-0.785-0.277
	   c-0.259-0.063-0.504-0.122-0.73-0.177c-0.903-0.218-1.508-0.362-1.508-0.362L362.395,414.982z"/>
   
	   <rect x="371.905" y="420.131" transform="matrix(0.7154 0.6987 -0.6987 0.7154 399.562 -140.617)" fill="#FFFFFF" width="0.992" height="0.121"/>
   
	   <rect x="376.674" y="429.697" transform="matrix(0.0373 0.9993 -0.9993 0.0373 792.5405 36.799)" fill="#FFFFFF" width="0.995" height="0.082"/>
   <path fill="#FFFFFF" d="M375.195,436.789c0,0-0.095,0.123-0.258,0.34c-0.174,0.209-0.378,0.543-0.701,0.879
	   c-0.332,0.328-0.673,0.762-1.141,1.113c-0.227,0.184-0.451,0.385-0.693,0.57c-0.256,0.168-0.517,0.338-0.776,0.508
	   c-0.253,0.184-0.534,0.32-0.813,0.443c-0.28,0.121-0.536,0.287-0.825,0.348c-0.281,0.082-0.552,0.16-0.809,0.232
	   c-0.262,0.055-0.516,0.07-0.746,0.105c-0.461,0.084-0.853,0.031-1.122,0.031c-0.272-0.008-0.428-0.012-0.428-0.012l0.003-0.023
	   c0,0,0.152,0.004,0.426,0.012c0.271-0.002,0.659,0.049,1.118-0.037c0.229-0.033,0.481-0.053,0.742-0.104
	   c0.255-0.076,0.522-0.154,0.802-0.236c0.286-0.059,0.543-0.227,0.823-0.348c0.278-0.127,0.556-0.262,0.808-0.445
	   c0.258-0.172,0.517-0.342,0.771-0.51c0.239-0.188,0.465-0.385,0.688-0.57c0.466-0.354,0.801-0.783,1.131-1.109
	   c0.32-0.336,0.523-0.672,0.696-0.881c0.164-0.217,0.257-0.34,0.257-0.34L375.195,436.789z"/>
   
	   <rect x="364.94" y="441.026" transform="matrix(-0.9518 -0.3068 0.3068 -0.9518 577.955 972.9079)" fill="#FFFFFF" width="0.993" height="0.015"/>
   
	   <rect x="183.697" y="482.471" transform="matrix(0.0452 -0.999 0.999 0.0452 -307.1981 645.689)" fill="#FFFFFF" width="0.993" height="2.169"/>
   <polygon fill="#FFFFFF" points="183.603,476.025 184.64,466.152 186.738,466.367 185.736,476.248 		"/>
   
	   <rect x="185.374" y="463.739" transform="matrix(0.1349 -0.9909 0.9909 0.1349 -299.7505 586.2428)" fill="#FFFFFF" width="0.991" height="2.103"/>
   
	   <rect x="187.106" y="452.968" transform="matrix(0.1857 -0.9826 0.9826 0.1857 -293.3397 554.0269)" fill="#FFFFFF" width="0.994" height="2.065"/>
   <polygon fill="#FFFFFF" points="188.133,446.462 190.556,436.837 192.502,437.324 190.112,446.957 		"/>
   
	   <rect x="191.437" y="434.633" transform="matrix(0.2734 -0.9619 0.9619 0.2734 -279.5776 501.1405)" fill="#FFFFFF" width="0.993" height="2"/>
   
	   <rect x="194.677" y="424.244" transform="matrix(0.3227 -0.9465 0.9465 0.3227 -270.2857 472.7302)" fill="#FFFFFF" width="0.993" height="1.961"/>
   <polygon fill="#FFFFFF" points="196.796,417.848 200.555,408.657 202.315,409.374 198.589,418.577 		"/>
   
	   <rect x="201.54" y="406.701" transform="matrix(0.4066 -0.9136 0.9136 0.4066 -252.5415 426.482)" fill="#FFFFFF" width="0.992" height="1.894"/>
   
	   <rect x="206.198" y="396.864" transform="matrix(0.4531 -0.8915 0.8915 0.4531 -241.5743 401.8118)" fill="#FFFFFF" width="0.994" height="1.857"/>
   <path fill="#FFFFFF" d="M209.391,390.746c0,0,0.308-0.541,0.765-1.351c0.229-0.405,0.497-0.878,0.784-1.385
	   c0.145-0.253,0.291-0.515,0.441-0.78l0.243-0.425l0.242-0.396c1.288-2.122,2.577-4.243,2.577-4.243l1.537,0.93
	   c0,0-1.28,2.126-2.563,4.252l-0.24,0.398l-0.213,0.377c-0.15,0.267-0.297,0.529-0.439,0.782c-0.284,0.508-0.551,0.981-0.778,1.387
	   c-0.455,0.813-0.761,1.354-0.761,1.354L209.391,390.746z"/>
   
	   <rect x="215.504" y="380.496" transform="matrix(0.547 -0.8372 0.8372 0.547 -221.4265 353.6068)" fill="#FFFFFF" width="0.994" height="1.789"/>
   
	   <rect x="221.861" y="371.578" transform="matrix(0.6143 -0.7891 0.7891 0.6143 -208.1289 319.1025)" fill="#FFFFFF" width="0.992" height="1.752"/>
   <polygon fill="#FFFFFF" points="226.445,366.127 233.262,358.907 234.494,360.067 227.703,367.312 		"/>
   
	   <rect x="234.457" y="357.606" transform="matrix(0.7212 -0.6927 0.6927 0.7212 -182.8008 262.6881)" fill="#FFFFFF" width="0.994" height="1.687"/>
   
	   <rect x="242.633" y="350.374" transform="matrix(0.7783 -0.6279 0.6279 0.7783 -166.6175 230.537)" fill="#FFFFFF" width="0.994" height="1.647"/>
   <polygon fill="#FFFFFF" points="248.595,346.011 256.876,340.532 257.754,341.855 249.493,347.364 		"/>
   
	   <rect x="258.073" y="339.641" transform="matrix(0.8601 -0.5102 0.5102 0.8601 -137.4944 179.5422)" fill="#FFFFFF" width="0.993" height="1.582"/>
   
	   <rect x="267.682" y="334.463" transform="matrix(0.8999 -0.4362 0.4362 0.8999 -119.3638 150.5384)" fill="#FFFFFF" width="0.993" height="1.544"/>
   <polygon fill="#FFFFFF" points="274.718,331.459 284.019,327.992 284.54,329.383 275.251,332.882 		"/>
   
	   <rect x="285.209" y="327.489" transform="matrix(0.953 -0.3029 0.3029 0.953 -86.0029 101.9672)" fill="#FFFFFF" width="0.993" height="1.477"/>
   
	   <rect x="295.71" y="324.608" transform="matrix(0.9748 -0.2233 0.2233 0.9748 -65.161 74.3496)" fill="#FFFFFF" width="0.993" height="1.439"/>
   <polygon fill="#FFFFFF" points="303.432,323.171 313.278,321.883 313.46,323.252 303.618,324.573 		"/>
   
	   <rect x="314.363" y="321.754" transform="matrix(0.9968 -0.0798 0.0798 0.9968 -24.722 26.1487)" fill="#FFFFFF" width="0.991" height="1.373"/>
   <rect x="325.268" y="321.324" fill="#FFFFFF" width="0.992" height="1.335"/>
   <polygon fill="#FFFFFF" points="333.266,321.56 343.148,322.531 343.025,323.802 333.14,322.862 		"/>
   
	   <rect x="344.086" y="322.735" transform="matrix(0.989 0.1478 -0.1478 0.989 51.5643 -47.3665)" fill="#FFFFFF" width="0.991" height="1.268"/>
   
	   <rect x="354.791" y="324.799" transform="matrix(0.9735 0.2286 -0.2286 0.9735 83.8092 -72.6098)" fill="#FFFFFF" width="0.993" height="1.231"/>
   <polygon fill="#FFFFFF" points="362.675,326.751 372.073,329.945 371.698,331.055 362.288,327.894 		"/>
   
	   <rect x="372.777" y="330.429" transform="matrix(0.9301 0.3672 -0.3672 0.9301 147.6394 -113.9505)" fill="#FFFFFF" width="0.993" height="1.164"/>
   
	   <rect x="382.783" y="334.883" transform="matrix(0.8956 0.4448 -0.4448 0.8956 189.2355 -135.4828)" fill="#FFFFFF" width="0.992" height="1.125"/>
   <path fill="#FFFFFF" d="M390.115,338.471c0,0,0.135,0.077,0.369,0.212c0.118,0.068,0.261,0.15,0.425,0.244
	   c0.167,0.101,0.354,0.214,0.561,0.336c0.793,0.489,1.85,1.141,2.907,1.792c0.533,0.312,1.042,0.688,1.526,1.019
	   c0.483,0.334,0.936,0.647,1.321,0.915c0.771,0.535,1.288,0.893,1.288,0.893l-0.63,0.861c0,0-0.506-0.348-1.268-0.871
	   c-0.38-0.261-0.821-0.566-1.298-0.893c-0.479-0.324-0.969-0.688-1.51-1.001c-1.06-0.647-2.119-1.295-2.913-1.78
	   c-0.194-0.116-0.373-0.223-0.531-0.316c-0.164-0.095-0.308-0.176-0.426-0.243c-0.235-0.135-0.37-0.21-0.37-0.21L390.115,338.471z"
	   />
   
	   <rect x="398.884" y="344.649" transform="matrix(0.7953 0.6063 -0.6063 0.7953 291.0477 -171.4617)" fill="#FFFFFF" width="0.992" height="1.059"/>
   
	   <rect x="407.135" y="351.873" transform="matrix(0.7061 0.7081 -0.7081 0.7061 369.313 -185.082)" fill="#FFFFFF" width="0.993" height="1.021"/>
   <path fill="#FFFFFF" d="M413.028,357.58c0,0,1.521,1.962,3.041,3.925c1.41,2.069,2.82,4.141,2.82,4.141l-0.812,0.518
	   c0,0-1.392-2.028-2.782-4.058c-1.527-1.957-3.057-3.914-3.057-3.914L413.028,357.58z"/>
   
	   <rect x="418.763" y="366.681" transform="matrix(0.5218 0.8531 -0.8531 0.5218 513.7084 -182.0793)" fill="#FFFFFF" width="0.992" height="0.956"/>
   
	   <rect x="423.831" y="376.374" transform="matrix(0.4014 0.9159 -0.9159 0.4014 599.1523 -163.0682)" fill="#FFFFFF" width="0.993" height="0.917"/>
   <path fill="#FFFFFF" d="M427.449,383.649c0,0,0.713,2.378,1.424,4.756c0.57,2.437,1.142,4.873,1.142,4.873l-0.841,0.171
	   c0,0-0.569-2.396-1.139-4.791c-0.721-2.376-1.44-4.752-1.44-4.752L427.449,383.649z"/>
   
	   <rect x="429.399" y="394.388" transform="matrix(0.181 0.9835 -0.9835 0.181 740.396 -99.431)" fill="#FFFFFF" width="0.993" height="0.852"/>
   
	   <rect x="430.626" y="405.275" transform="matrix(0.0463 0.9989 -0.9989 0.0463 816.3859 -43.7785)" fill="#FFFFFF" width="0.99" height="0.812"/>
   <path fill="#FFFFFF" d="M431.536,413.141c0,0-0.189,2.476-0.384,4.95c-0.346,2.477-0.69,4.952-0.69,4.952l-0.743-0.127
	   c0,0,0.333-2.441,0.666-4.882c0.184-2.477,0.365-4.952,0.365-4.952L431.536,413.141z"/>
   
	   <rect x="429.354" y="424.072" transform="matrix(-0.188 0.9822 -0.9822 -0.188 927.5291 82.0418)" fill="#FFFFFF" width="0.992" height="0.747"/>
   
	   <rect x="426.593" y="434.663" transform="matrix(-0.3195 0.9476 -0.9476 -0.3195 975.7525 169.2833)" fill="#FFFFFF" width="0.993" height="0.709"/>
   <path fill="#FFFFFF" d="M424.696,442.095c0,0-0.268,0.563-0.668,1.4c-0.199,0.422-0.434,0.91-0.684,1.438
	   c-0.255,0.523-0.5,1.092-0.813,1.635c-0.598,1.098-1.192,2.193-1.643,3.018c-0.451,0.828-0.779,1.348-0.779,1.348l-0.559-0.33
	   c0,0,0.332-0.531,0.77-1.342c0.439-0.813,1.025-1.898,1.611-2.984c0.306-0.533,0.551-1.107,0.805-1.631
	   c0.248-0.527,0.479-1.018,0.678-1.438c0.396-0.844,0.662-1.404,0.662-1.404L424.696,442.095z"/>
   
	   <rect x="418.55" y="451.675" transform="matrix(-0.5451 0.8384 -0.8384 -0.5451 1026.4124 347.0831)" fill="#FFFFFF" width="0.992" height="0.643"/>
   
	   <rect x="411.671" y="460.196" transform="matrix(-0.714 0.7002 -0.7002 -0.714 1028.8732 500.6796)" fill="#FFFFFF" width="0.993" height="0.605"/>
   <path fill="#FFFFFF" d="M406.619,465.531c0,0-2.084,1.348-4.167,2.697c-2.226,1.146-4.45,2.291-4.45,2.291l-0.227-0.494
	   c0,0,2.185-1.135,4.369-2.27c2.079-1.355,4.158-2.713,4.158-2.713L406.619,465.531z"/>
   
	   <rect x="396.041" y="470.572" transform="matrix(-0.9234 0.3839 -0.3839 -0.9234 943.4495 753.3622)" fill="#FFFFFF" width="0.992" height="0.537"/>
   
	   <rect x="385.609" y="473.71" transform="matrix(-0.9842 0.1773 -0.1773 -0.9842 850.1355 871.9438)" fill="#FFFFFF" width="0.991" height="0.5"/>
   <path fill="#FFFFFF" d="M378.676,474.994c0,0-2.482-0.053-4.965-0.104c-2.481-0.299-4.964-0.6-4.964-0.6l0.075-0.434
	   c0,0,2.448,0.287,4.896,0.574c2.481,0.041,4.964,0.084,4.964,0.084L378.676,474.994z"/>
   
	   <rect x="366.82" y="473.559" transform="matrix(-0.98 -0.1988 0.1988 -0.98 633.1346 1011.1062)" fill="#FFFFFF" width="0.992" height="0.432"/>
   
	   <rect x="356.465" y="470.287" transform="matrix(-0.9125 -0.4091 0.4091 -0.9125 490.2379 1045.824)" fill="#FFFFFF" width="0.993" height="0.396"/>
   <path fill="#FFFFFF" d="M350.281,467.093c0,0-0.499-0.369-1.249-0.922c-0.187-0.137-0.391-0.287-0.604-0.445
	   c-0.223-0.146-0.424-0.352-0.649-0.541c-0.441-0.385-0.914-0.797-1.387-1.207c-0.438-0.445-0.874-0.891-1.284-1.309
	   c-0.422-0.408-0.769-0.83-1.063-1.193c-0.599-0.721-0.996-1.199-0.996-1.199l0.267-0.207c0,0,0.396,0.475,0.991,1.186
	   c0.293,0.357,0.637,0.773,1.056,1.176c0.406,0.41,0.84,0.852,1.273,1.289c0.465,0.402,0.931,0.807,1.367,1.186
	   c0.222,0.184,0.419,0.381,0.642,0.529c0.216,0.158,0.418,0.307,0.606,0.443c0.751,0.551,1.253,0.916,1.253,0.916L350.281,467.093z
	   "/>
   
	   <rect x="341.801" y="458.781" transform="matrix(-0.5683 -0.8228 0.8228 -0.5683 159.1835 1001.4102)" fill="#FFFFFF" width="0.99" height="0.33"/>
   
	   <rect x="337.278" y="448.973" transform="matrix(-0.2485 -0.9686 0.9686 -0.2485 -13.3005 887.9188)" fill="#FFFFFF" width="0.994" height="0.291"/>
   <path fill="#FFFFFF" d="M336.663,441.728c0,0,0.02-0.619,0.046-1.549c0.011-0.467,0.02-1.008,0.102-1.59
	   c0.064-0.582,0.134-1.203,0.202-1.822c0.118-0.611,0.234-1.223,0.347-1.797c0.063-0.285,0.093-0.566,0.176-0.822
	   c0.072-0.258,0.141-0.5,0.202-0.727c0.251-0.898,0.419-1.498,0.419-1.498l0.222,0.07c0,0-0.164,0.596-0.41,1.488
	   c-0.062,0.225-0.128,0.467-0.197,0.723c-0.082,0.252-0.112,0.533-0.172,0.814c-0.107,0.57-0.223,1.178-0.338,1.783
	   c-0.065,0.613-0.132,1.227-0.193,1.801c-0.079,0.572-0.087,1.115-0.097,1.582c-0.022,0.93-0.039,1.551-0.039,1.551
	   L336.663,441.728z"/>
   
	   <rect x="338.235" y="430.443" transform="matrix(0.3528 -0.9357 0.9357 0.3528 -183.6509 595.5803)" fill="#FFFFFF" width="0.994" height="0.225"/>
   
	   <rect x="344.104" y="421.332" transform="matrix(0.7381 -0.6747 0.6747 0.7381 -194.0775 342.8429)" fill="#FFFFFF" width="0.992" height="0.188"/>
   <path fill="#FFFFFF" d="M350.876,417.502c0,0,0.596-0.174,1.488-0.435c0.225-0.065,0.465-0.137,0.722-0.211
	   c0.254-0.082,0.541-0.097,0.827-0.151c0.578-0.089,1.195-0.184,1.813-0.279c0.622-0.016,1.244-0.031,1.828-0.045
	   c0.291,0.004,0.575-0.041,0.84,0.005c0.267,0.027,0.518,0.056,0.749,0.079c0.93,0.1,1.548,0.165,1.548,0.165l-0.021,0.126
	   c0,0-0.614-0.063-1.537-0.158c-0.23-0.022-0.48-0.05-0.744-0.075c-0.264-0.046-0.545,0.001-0.835-0.002
	   c-0.579,0.016-1.196,0.032-1.814,0.051c-0.608,0.097-1.218,0.192-1.789,0.283c-0.283,0.055-0.564,0.067-0.818,0.15
	   c-0.255,0.076-0.498,0.147-0.722,0.214c-0.893,0.265-1.486,0.438-1.486,0.438L350.876,417.502z"/>
   
	   <rect x="361.633" y="416.953" transform="matrix(0.9685 0.249 -0.249 0.9685 115.2496 -77.0393)" fill="#FFFFFF" width="0.992" height="0.121"/>
   
	   <rect x="370.554" y="422.847" transform="matrix(0.5332 0.846 -0.846 0.5332 530.9795 -116.4956)" fill="#FFFFFF" width="0.993" height="0.083"/>
   <path fill="#FFFFFF" d="M372.865,429.984c0,0-0.019,0.154-0.053,0.424c-0.046,0.268-0.056,0.658-0.167,1.111
	   c-0.124,0.449-0.201,0.994-0.432,1.533c-0.104,0.273-0.198,0.561-0.316,0.842c-0.138,0.273-0.278,0.551-0.418,0.828
	   c-0.126,0.285-0.302,0.545-0.48,0.791c-0.184,0.244-0.32,0.518-0.541,0.713c-0.202,0.213-0.397,0.414-0.584,0.607
	   c-0.2,0.176-0.41,0.318-0.595,0.463c-0.355,0.305-0.721,0.453-0.956,0.59c-0.239,0.129-0.376,0.203-0.376,0.203l-0.01-0.021
	   c0,0,0.136-0.074,0.374-0.203c0.234-0.137,0.597-0.285,0.951-0.592c0.182-0.145,0.392-0.285,0.59-0.463
	   c0.184-0.189,0.377-0.395,0.577-0.604c0.217-0.195,0.354-0.469,0.538-0.713c0.179-0.25,0.352-0.506,0.476-0.791
	   c0.14-0.277,0.278-0.553,0.415-0.826c0.114-0.281,0.209-0.566,0.311-0.84c0.229-0.537,0.303-1.078,0.425-1.525
	   c0.108-0.449,0.119-0.842,0.163-1.109c0.032-0.271,0.052-0.424,0.052-0.424L372.865,429.984z"/>
   
	   <rect x="366.058" y="438.526" transform="matrix(-0.9776 0.2107 -0.2107 -0.9776 817.2681 790.0026)" fill="#FFFFFF" width="0.992" height="0.016"/>
   
	   <rect x="207.868" y="490.39" transform="matrix(-0.1044 -0.9945 0.9945 -0.1044 -258.5411 749.8477)" fill="#FFFFFF" width="0.992" height="1.894"/>
   
	   <rect x="206.944" y="479.509" transform="matrix(-0.0531 -0.9986 0.9986 -0.0531 -261.3088 713.0848)" fill="#FFFFFF" width="0.994" height="1.855"/>
   <path fill="#FFFFFF" d="M206.255,473.015c0,0-0.005-0.621-0.013-1.553c-0.005-0.465-0.01-1.008-0.015-1.59
	   c-0.003-0.291-0.006-0.592-0.008-0.898l-0.002-0.486l0.011-0.467c0.057-2.48,0.111-4.965,0.111-4.965l1.796,0.037
	   c0,0-0.047,2.482-0.094,4.965l-0.009,0.465l0.004,0.434c0.004,0.307,0.007,0.605,0.011,0.896c0.007,0.582,0.013,1.125,0.019,1.592
	   c0.013,0.932,0.02,1.551,0.02,1.551L206.255,473.015z"/>
   
	   <rect x="206.82" y="460.664" transform="matrix(0.0541 -0.9985 0.9985 0.0541 -264.774 643.6232)" fill="#FFFFFF" width="0.994" height="1.791"/>
   
	   <rect x="207.829" y="449.825" transform="matrix(0.1376 -0.9905 0.9905 0.1376 -266.7487 595.0438)" fill="#FFFFFF" width="0.994" height="1.752"/>
   <polygon fill="#FFFFFF" points="208.715,443.167 211.008,433.505 212.655,433.894 210.396,443.562 		"/>
   
	   <rect x="211.729" y="431.427" transform="matrix(0.2794 -0.9602 0.9602 0.2794 -262.1248 515.2694)" fill="#FFFFFF" width="0.993" height="1.685"/>
   
	   <rect x="215.217" y="421.078" transform="matrix(0.358 -0.9337 0.9337 0.358 -255.4494 472.2823)" fill="#FFFFFF" width="0.996" height="1.647"/>
   <polygon fill="#FFFFFF" points="217.839,414.671 222.271,405.786 223.693,406.492 219.292,415.394 		"/>
   
	   <rect x="223.217" y="404.053" transform="matrix(0.4907 -0.8713 0.8713 0.4907 -238.8147 401.0996)" fill="#FFFFFF" width="0.993" height="1.581"/>
   
	   <rect x="228.94" y="394.788" transform="matrix(0.5613 -0.8276 0.8276 0.5613 -226.7178 363.4311)" fill="#FFFFFF" width="0.992" height="1.543"/>
   <polygon fill="#FFFFFF" points="233.186,389.007 239.508,381.354 240.653,382.297 234.358,389.973 		"/>
   
	   <rect x="240.589" y="379.973" transform="matrix(0.6739 -0.7388 0.7388 0.6739 -202.6607 302.2715)" fill="#FFFFFF" width="0.993" height="1.477"/>
   
	   <rect x="248.272" y="372.233" transform="matrix(0.7332 -0.68 0.68 0.7332 -187.2443 268.6829)" fill="#FFFFFF" width="0.992" height="1.438"/>
   <polygon fill="#FFFFFF" points="253.909,367.473 261.792,361.435 262.633,362.528 254.771,368.594 		"/>
   
	   <rect x="262.946" y="360.457" transform="matrix(0.8229 -0.5682 0.5682 0.8229 -158.5506 213.6698)" fill="#FFFFFF" width="0.993" height="1.373"/>
   
	   <rect x="272.165" y="354.587" transform="matrix(0.8672 -0.498 0.498 0.8672 -140.7103 182.9932)" fill="#FFFFFF" width="0.992" height="1.336"/>
   <polygon fill="#FFFFFF" points="278.939,351.159 287.984,347.06 288.513,348.221 279.482,352.353 		"/>
   
	   <rect x="289.13" y="346.452" transform="matrix(0.9305 -0.3663 0.3663 0.9305 -107.0059 130.2103)" fill="#FFFFFF" width="0.992" height="1.268"/>
   
	   <rect x="299.454" y="342.863" transform="matrix(0.9578 -0.2874 0.2874 0.9578 -86.0705 100.7121)" fill="#FFFFFF" width="0.992" height="1.23"/>
   <polygon fill="#FFFFFF" points="307.004,340.951 316.74,339.019 316.973,340.166 307.24,342.133 		"/>
   
	   <rect x="317.84" y="338.794" transform="matrix(0.9893 -0.1457 0.1457 0.9893 -46.0478 50.0002)" fill="#FFFFFF" width="0.992" height="1.164"/>
   
	   <rect x="328.68" y="337.623" transform="matrix(0.998 -0.0639 0.0639 0.998 -20.9401 21.7285)" fill="#FFFFFF" width="0.993" height="1.126"/>
   <path fill="#FFFFFF" d="M336.629,337.38c0,0,0.154,0,0.427,0c0.136-0.001,0.301-0.002,0.489-0.002
	   c0.194,0.004,0.414,0.008,0.652,0.011c0.932,0.027,2.172,0.063,3.413,0.098c0.618,0.004,1.247,0.075,1.832,0.119
	   c0.586,0.048,1.133,0.093,1.603,0.132c0.937,0.077,1.562,0.129,1.562,0.129l-0.114,1.061c0,0-0.612-0.048-1.531-0.121
	   c-0.46-0.035-0.996-0.078-1.57-0.124c-0.575-0.041-1.184-0.11-1.809-0.111c-1.24-0.031-2.481-0.063-3.412-0.085
	   c-0.229-0.004-0.436-0.006-0.619-0.009c-0.188,0.001-0.354,0.002-0.489,0.003c-0.271,0.002-0.427,0.002-0.427,0.002
	   L336.629,337.38z"/>
   
	   <rect x="347.523" y="338.06" transform="matrix(0.9919 0.1271 -0.1271 0.9919 45.8438 -41.4765)" fill="#FFFFFF" width="0.991" height="1.06"/>
   
	   <rect x="358.251" y="340.158" transform="matrix(0.9654 0.2608 -0.2608 0.9654 101.2549 -81.7678)" fill="#FFFFFF" width="0.992" height="1.021"/>
   <path fill="#FFFFFF" d="M366.026,342.473c0,0,2.298,0.938,4.597,1.877c2.256,1.088,4.513,2.177,4.513,2.177l-0.443,0.853
	   c0,0-2.219-1.061-4.438-2.121c-2.302-0.932-4.603-1.861-4.603-1.861L366.026,342.473z"/>
   
	   <rect x="375.737" y="347.176" transform="matrix(0.8775 0.4796 -0.4796 0.8775 212.8337 -137.8516)" fill="#FFFFFF" width="0.992" height="0.954"/>
   
	   <rect x="384.943" y="353.046" transform="matrix(0.8054 0.5927 -0.5927 0.8054 284.5381 -159.6696)" fill="#FFFFFF" width="0.992" height="0.917"/>
   <path fill="#FFFFFF" d="M391.552,357.837c0,0,1.805,1.704,3.61,3.407c1.711,1.825,3.425,3.65,3.425,3.65l-0.644,0.568
	   c0,0-1.69-1.79-3.383-3.58c-1.811-1.697-3.622-3.396-3.622-3.396L391.552,357.837z"/>
   
	   <rect x="398.749" y="365.876" transform="matrix(0.6477 0.7619 -0.7619 0.6477 419.734 -175.1352)" fill="#FFFFFF" width="0.992" height="0.851"/>
   
	   <rect x="405.238" y="374.647" transform="matrix(0.5396 0.8419 -0.8419 0.5396 502.54 -168.9268)" fill="#FFFFFF" width="0.994" height="0.813"/>
   <path fill="#FFFFFF" d="M409.837,381.334c0,0,1.07,2.239,2.143,4.479c0.939,2.317,1.877,4.636,1.877,4.636l-0.707,0.262
	   c0,0-0.932-2.282-1.865-4.563c-1.079-2.236-2.157-4.472-2.157-4.472L409.837,381.334z"/>
   
	   <rect x="413.526" y="391.593" transform="matrix(0.3296 0.9441 -0.9441 0.3296 647.6088 -128.1196)" fill="#FFFFFF" width="0.992" height="0.746"/>
   <polygon fill="#FFFFFF" points="417.153,401.944 417.349,402.917 416.655,403.059 416.453,402.086 		"/>
   <path fill="#FFFFFF" d="M418.39,409.831c0,0,0.049,0.618,0.122,1.547c0.036,0.463,0.081,1.005,0.126,1.585
	   c0.041,0.581,0.113,1.196,0.114,1.822c0.03,1.249,0.063,2.497,0.087,3.433c0.021,0.944-0.002,1.56-0.002,1.56l-0.65-0.009
	   c0,0,0.022-0.626-0.002-1.545c-0.027-0.925-0.063-2.157-0.098-3.391c-0.003-0.616-0.078-1.235-0.12-1.815
	   c-0.048-0.58-0.093-1.121-0.131-1.586c-0.077-0.927-0.131-1.545-0.131-1.545L418.39,409.831z"/>
   
	   <rect x="417.952" y="420.924" transform="matrix(-0.0531 0.9986 -0.9986 -0.0531 861.3195 25.7547)" fill="#FFFFFF" width="0.993" height="0.642"/>
   
	   <rect x="416.211" y="431.718" transform="matrix(-0.2698 0.9629 -0.9629 -0.2698 945.126 147.3103)" fill="#FFFFFF" width="0.992" height="0.602"/>
   <path fill="#FFFFFF" d="M414.451,439.164c0,0-1.129,2.211-2.26,4.42c-1.354,2.105-2.708,4.211-2.708,4.211l-0.443-0.318
	   c0,0,1.325-2.072,2.65-4.146c1.122-2.215,2.244-4.428,2.244-4.428L414.451,439.164z"/>
   
	   <rect x="407.912" y="448.553" transform="matrix(-0.6063 0.7953 -0.7953 -0.6063 1012.9445 396.1484)" fill="#FFFFFF" width="0.992" height="0.539"/>
   
	   <rect x="400.395" y="456.497" transform="matrix(-0.7633 0.6461 -0.6461 -0.7633 1001.9736 546.3658)" fill="#FFFFFF" width="0.992" height="0.501"/>
   <path fill="#FFFFFF" d="M394.983,461.332c0,0-2.174,1.197-4.35,2.393c-2.299,0.982-4.599,1.965-4.599,1.965l-0.151-0.414
	   c0,0,2.264-0.977,4.526-1.951c2.172-1.203,4.343-2.408,4.343-2.408L394.983,461.332z"/>
   
	   <rect x="384.062" y="465.765" transform="matrix(-0.9486 0.3165 -0.3165 -0.9486 896.8391 786.2819)" fill="#FFFFFF" width="0.993" height="0.433"/>
   
	   <rect x="373.425" y="468.124" transform="matrix(-0.9946 0.1042 -0.1042 -0.9946 794.5965 895.1448)" fill="#FFFFFF" width="0.994" height="0.397"/>
   <path fill="#FFFFFF" d="M366.444,468.687c0,0-0.617-0.068-1.542-0.174c-0.23-0.023-0.481-0.053-0.748-0.082
	   c-0.265-0.02-0.543-0.094-0.831-0.145c-0.576-0.111-1.189-0.232-1.806-0.354c-0.601-0.166-1.203-0.334-1.767-0.49
	   c-0.569-0.143-1.08-0.334-1.519-0.502c-0.879-0.324-1.464-0.541-1.464-0.541l0.129-0.313c0,0,0.579,0.213,1.449,0.531
	   c0.434,0.162,0.938,0.352,1.502,0.49c0.559,0.152,1.153,0.314,1.749,0.479c0.604,0.115,1.208,0.232,1.774,0.342
	   c0.284,0.047,0.554,0.121,0.82,0.139c0.267,0.027,0.518,0.057,0.748,0.082c0.926,0.1,1.543,0.166,1.543,0.166L366.444,468.687z"/>
   
	   <rect x="354.991" y="465.457" transform="matrix(-0.9033 -0.4289 0.4289 -0.9033 476.885 1038.7161)" fill="#FFFFFF" width="0.988" height="0.331"/>
   
	   <rect x="346.153" y="459.193" transform="matrix(-0.6997 -0.7144 0.7144 -0.6997 261.0223 1028.3953)" fill="#FFFFFF" width="0.992" height="0.291"/>
   <path fill="#FFFFFF" d="M341.968,453.531c0,0-0.294-0.545-0.735-1.365c-0.224-0.408-0.486-0.881-0.707-1.426
	   c-0.234-0.537-0.484-1.107-0.734-1.682c-0.203-0.588-0.407-1.176-0.6-1.729c-0.089-0.277-0.203-0.537-0.259-0.801
	   c-0.066-0.26-0.13-0.504-0.188-0.73c-0.232-0.904-0.388-1.506-0.388-1.506l0.229-0.049c0,0,0.154,0.598,0.39,1.494
	   c0.059,0.225,0.121,0.467,0.188,0.725c0.057,0.26,0.171,0.518,0.261,0.793c0.19,0.547,0.396,1.129,0.6,1.713
	   c0.25,0.563,0.499,1.127,0.732,1.656c0.218,0.535,0.481,1.008,0.708,1.416c0.443,0.816,0.739,1.363,0.739,1.363L341.968,453.531z"
	   />
   
	   <rect x="337.708" y="442.667" transform="matrix(-0.1625 -0.9867 0.9867 -0.1625 -43.7414 848.434)" fill="#FFFFFF" width="0.992" height="0.227"/>
   
	   <rect x="338.181" y="431.899" transform="matrix(0.3026 -0.9531 0.9531 0.3026 -175.5524 624.0512)" fill="#FFFFFF" width="0.994" height="0.186"/>
   <path fill="#FFFFFF" d="M342.161,425.443c0,0,0.43-0.449,1.071-1.121c0.161-0.168,0.336-0.352,0.521-0.543
	   c0.179-0.197,0.42-0.354,0.642-0.545c0.456-0.365,0.942-0.758,1.429-1.148c0.531-0.324,1.063-0.649,1.562-0.954
	   c0.254-0.142,0.478-0.322,0.729-0.415c0.244-0.107,0.477-0.211,0.688-0.306c0.854-0.379,1.423-0.631,1.423-0.631l0.044,0.12
	   c0,0-0.563,0.252-1.41,0.632c-0.212,0.095-0.439,0.196-0.683,0.306c-0.252,0.093-0.473,0.273-0.725,0.416
	   c-0.493,0.302-1.021,0.626-1.546,0.951c-0.479,0.389-0.959,0.775-1.408,1.139c-0.218,0.191-0.455,0.342-0.634,0.543
	   c-0.184,0.193-0.356,0.375-0.518,0.545c-0.642,0.674-1.068,1.123-1.068,1.123L342.161,425.443z"/>
   
	   <rect x="351.19" y="419.343" transform="matrix(0.9633 -0.2684 0.2684 0.9633 -99.6488 109.7598)" fill="#FFFFFF" width="0.993" height="0.122"/>
   
	   <rect x="361.806" y="419.955" transform="matrix(0.884 0.4676 -0.4676 0.884 238.4209 -120.6638)" fill="#FFFFFF" width="0.992" height="0.082"/>
   <path fill="#FFFFFF" d="M367.447,425.259c0,0,0.062,0.143,0.165,0.393c0.095,0.256,0.281,0.598,0.411,1.047
	   c0.119,0.451,0.323,0.963,0.396,1.543c0.047,0.289,0.105,0.584,0.146,0.887c0.018,0.305,0.035,0.615,0.052,0.926
	   c0.034,0.313,0.012,0.625-0.021,0.928c-0.036,0.303-0.021,0.607-0.112,0.889c-0.069,0.283-0.138,0.559-0.201,0.816
	   c-0.086,0.254-0.196,0.48-0.283,0.697c-0.156,0.443-0.398,0.754-0.533,0.99c-0.144,0.229-0.226,0.363-0.226,0.363l-0.02-0.016
	   c0,0,0.082-0.133,0.224-0.361c0.134-0.236,0.373-0.549,0.527-0.988c0.085-0.215,0.195-0.443,0.28-0.695
	   c0.063-0.258,0.129-0.529,0.196-0.813c0.091-0.277,0.073-0.584,0.109-0.889c0.029-0.305,0.052-0.609,0.017-0.92
	   c-0.018-0.311-0.036-0.619-0.054-0.924c-0.042-0.301-0.103-0.596-0.15-0.883c-0.072-0.578-0.277-1.084-0.396-1.533
	   c-0.13-0.443-0.319-0.789-0.414-1.043c-0.106-0.25-0.167-0.393-0.167-0.393L367.447,425.259z"/>
   
	   <rect x="365.715" y="435.833" transform="matrix(-0.7402 0.6724 -0.6724 -0.7402 930.3356 512.2206)" fill="#FFFFFF" width="0.993" height="0.016"/>
   <path fill="#FFFFFF" d="M225.003,481.218l0.247,4.996l0.484,4.977c0.081,0.68,0.187,1.355,0.274,2.035
	   c0.547-0.072,1.106-0.068,1.654,0.018c-0.104-0.746-0.22-1.49-0.311-2.238l-0.497-4.912l-0.262-4.934
	   c-0.124-1.646-0.064-3.295-0.088-4.945l-0.009-2.473c0.008-0.824,0.064-1.648,0.097-2.475c0.092-1.646,0.105-3.301,0.263-4.943
	   l0.459-4.934c0.442-3.271,0.843-6.557,1.522-9.791c2.434-12.998,6.99-25.57,13.18-37.193c3.146-5.787,6.691-11.338,10.6-16.604
	   c3.979-5.213,8.313-10.123,12.939-14.706c4.67-4.543,9.681-8.684,14.885-12.503c2.668-1.818,5.285-3.698,8.063-5.32
	   c1.386-0.837,2.696-1.705,4.132-2.446l2.115-1.156c0.71-0.366,1.43-0.696,2.144-1.045c11.458-5.57,24.147-8.149,36.523-7.874
	   c6.191,0.171,12.313,1.039,18.211,2.548c5.901,1.499,11.551,3.724,16.854,6.451c5.298,2.744,10.255,6.018,14.714,9.781
	   c4.461,3.76,8.457,7.96,11.924,12.467c3.468,4.517,6.449,9.288,8.661,14.368c2.228,5.024,3.395,10.438,3.777,15.748l0.153,1.994
	   c0.014,0.664-0.021,1.327-0.027,1.989l-0.041,1.982l-0.022,0.988l-0.115,0.979c-0.16,1.303-0.289,2.605-0.474,3.895
	   c-0.272,1.27-0.547,2.539-0.817,3.799c-1.294,4.996-3.301,9.688-5.844,13.953c-2.64,4.211-5.844,7.904-9.34,11.123
	   c-1.876,1.457-3.609,3.061-5.619,4.234c-0.982,0.607-1.92,1.313-2.924,1.832l-1.529,0.787l-0.762,0.391l-0.786,0.313
	   c-4.154,1.74-8.6,2.504-12.87,2.354c-4.278-0.143-8.395-1.184-12.041-2.896c-3.653-1.713-6.834-4.104-9.387-6.873
	   c-0.344-0.322-0.644-0.684-0.93-1.055c-0.292-0.363-0.583-0.727-0.873-1.086c-0.613-0.697-1.068-1.498-1.573-2.256
	   c-1.056-1.471-1.764-3.15-2.347-4.773c-1.05-3.326-1.184-6.781-0.464-9.9c0.659-3.139,2.131-5.914,3.993-8.127
	   c1.867-2.209,4.107-3.924,6.476-4.943c1.187-0.5,2.428-0.771,3.632-0.842c1.203-0.07,2.384,0.006,3.477,0.285
	   c4.427,1.064,7.306,4.336,8.197,7.266c0.415,1.502,0.289,2.934-0.067,4.057c-0.353,1.135-0.908,1.992-1.434,2.598
	   c-0.525,0.609-1.015,0.984-1.349,1.221c-0.343,0.221-0.517,0.336-0.517,0.336s0.174-0.113,0.519-0.334
	   c0.333-0.234,0.824-0.607,1.354-1.217c0.527-0.604,1.088-1.461,1.444-2.598c0.361-1.125,0.492-2.561,0.081-4.07
	   c-0.886-2.945-3.762-6.24-8.213-7.326c-1.098-0.285-2.285-0.369-3.498-0.301c-1.216,0.064-2.464,0.332-3.666,0.836
	   c-2.388,1.02-4.645,2.734-6.533,4.951c-1.883,2.221-3.375,5.014-4.052,8.174c-0.735,3.145-0.615,6.633,0.435,9.998
	   c0.595,1.666,1.284,3.322,2.353,4.828c0.505,0.764,0.961,1.57,1.576,2.275c0.291,0.363,0.583,0.729,0.877,1.098
	   c0.286,0.375,0.586,0.738,0.932,1.066c2.563,2.801,5.762,5.221,9.44,6.961c3.673,1.744,7.823,2.809,12.146,2.969
	   c4.32,0.164,8.801-0.582,13.034-2.34l0.802-0.314l0.764-0.389l1.532-0.781c1.039-0.537,1.966-1.229,2.965-1.844
	   c2.03-1.178,3.785-2.787,5.681-4.248c3.537-3.232,6.781-6.949,9.46-11.189c2.583-4.295,4.626-9.027,5.951-14.07
	   c0.279-1.273,0.56-2.553,0.842-3.838c0.188-1.301,0.325-2.617,0.491-3.936l0.119-0.988l0.027-0.998l0.049-2.005
	   c0.01-0.671,0.045-1.343,0.033-2.014l-0.146-2.019c-0.373-5.384-1.521-10.833-3.778-15.978c-2.199-5.104-5.214-9.97-8.687-14.521
	   c-3.479-4.559-7.496-8.81-11.986-12.621c-4.488-3.813-9.48-7.139-14.823-9.928c-5.348-2.774-11.047-5.043-17.009-6.579
	   c-5.956-1.545-12.142-2.445-18.407-2.64c-12.524-0.321-25.369,2.235-37.022,7.851c-0.728,0.353-1.468,0.69-2.187,1.058
	   l-2.119,1.148c-1.427,0.731-2.812,1.635-4.188,2.459c-2.805,1.626-5.451,3.513-8.146,5.339
	   c-5.265,3.834-10.335,7.993-15.064,12.563c-4.688,4.61-9.08,9.553-13.117,14.803c-3.965,5.305-7.566,10.898-10.765,16.736
	   c-6.296,11.72-10.946,24.414-13.454,37.55c-0.699,3.271-1.116,6.59-1.575,9.898l-0.481,4.99c-0.164,1.662-0.185,3.334-0.283,5.002
	   c-0.033,0.834-0.095,1.668-0.105,2.502v2.504C224.949,477.882,224.884,479.552,225.003,481.218z"/>
   <polygon fill="#FFFFFF" points="246.775,495.638 245.488,485.792 246.856,485.611 248.178,495.451 		"/>
   
	   <rect x="245.512" y="483.522" transform="matrix(-0.0816 -0.9967 0.9967 -0.0816 -216.5163 768.8994)" fill="#FFFFFF" width="0.994" height="1.374"/>
   <rect x="244.928" y="472.81" fill="#FFFFFF" width="1.334" height="0.992"/>
   <polygon fill="#FFFFFF" points="245.163,465.804 246.136,455.921 247.405,456.044 246.467,465.929 		"/>
   
	   <rect x="246.507" y="453.871" transform="matrix(0.1478 -0.989 0.989 0.1478 -239.0109 631.6383)" fill="#FFFFFF" width="0.991" height="1.268"/>
   
	   <rect x="248.55" y="443.181" transform="matrix(0.2305 -0.9731 0.9731 0.2305 -240.1996 583.8569)" fill="#FFFFFF" width="0.992" height="1.229"/>
   <polygon fill="#FFFFFF" points="250.354,436.394 253.55,426.996 254.659,427.371 251.497,436.781 		"/>
   
	   <rect x="254.157" y="425.202" transform="matrix(0.3683 -0.9297 0.9297 0.3683 -234.9934 505.7039)" fill="#FFFFFF" width="0.992" height="1.165"/>
   
	   <rect x="258.56" y="415.229" transform="matrix(0.4445 -0.8958 0.8958 0.4445 -228.5489 463.0526)" fill="#FFFFFF" width="0.993" height="1.125"/>
   <path fill="#FFFFFF" d="M262.074,408.954c0,0,0.077-0.135,0.213-0.37c0.066-0.118,0.148-0.261,0.243-0.425
	   c0.101-0.167,0.214-0.354,0.337-0.561c0.487-0.793,1.14-1.851,1.791-2.906c0.312-0.534,0.688-1.042,1.019-1.527
	   c0.335-0.483,0.647-0.934,0.915-1.321c0.536-0.771,0.894-1.287,0.894-1.287l0.861,0.629c0,0-0.349,0.508-0.871,1.268
	   c-0.262,0.379-0.565,0.822-0.894,1.298c-0.322,0.478-0.688,0.969-1,1.51c-0.647,1.06-1.295,2.119-1.781,2.913
	   c-0.115,0.194-0.222,0.373-0.316,0.531c-0.094,0.164-0.175,0.308-0.241,0.426c-0.136,0.235-0.212,0.37-0.212,0.37L262.074,408.954
	   z"/>
   
	   <rect x="268.297" y="399.129" transform="matrix(0.606 -0.7955 0.7955 0.606 -212.0115 371.3095)" fill="#FFFFFF" width="0.994" height="1.06"/>
   
	   <rect x="275.495" y="390.93" transform="matrix(0.7081 -0.7061 0.7061 0.7081 -195.84 309.1493)" fill="#FFFFFF" width="0.993" height="1.021"/>
   <path fill="#FFFFFF" d="M281.184,386.041c0,0,1.962-1.52,3.925-3.042c2.07-1.409,4.142-2.819,4.142-2.819l0.518,0.811
	   c0,0-2.028,1.392-4.057,2.783c-1.957,1.528-3.914,3.056-3.914,3.056L281.184,386.041z"/>
   
	   <rect x="290.26" y="379.311" transform="matrix(0.8528 -0.5223 0.5223 0.8528 -155.5502 207.7736)" fill="#FFFFFF" width="0.993" height="0.955"/>
   
	   <rect x="299.939" y="374.281" transform="matrix(0.9157 -0.4018 0.4018 0.9157 -125.2518 152.2949)" fill="#FFFFFF" width="0.993" height="0.917"/>
   <path fill="#FFFFFF" d="M307.253,371.619c0,0,2.378-0.712,4.757-1.424c2.436-0.57,4.872-1.141,4.872-1.141l0.172,0.841
	   c0,0-2.396,0.569-4.792,1.138c-2.376,0.721-4.752,1.44-4.752,1.44L307.253,371.619z"/>
   
	   <rect x="317.947" y="368.759" transform="matrix(0.9837 -0.1797 0.1797 0.9837 -61.1613 63.2373)" fill="#FFFFFF" width="0.992" height="0.851"/>
   
	   <rect x="328.77" y="367.558" transform="matrix(0.9989 -0.0463 0.0463 0.9989 -16.668 15.6244)" fill="#FFFFFF" width="0.992" height="0.812"/>
   <path fill="#FFFFFF" d="M336.743,367.532c0,0,2.477,0.191,4.95,0.383c2.477,0.347,4.953,0.691,4.953,0.691l-0.126,0.744
	   c0,0-2.443-0.334-4.885-0.666c-2.477-0.184-4.952-0.367-4.952-0.367L336.743,367.532z"/>
   
	   <rect x="347.585" y="368.868" transform="matrix(0.9821 0.1883 -0.1883 0.9821 75.7701 -58.9481)" fill="#FFFFFF" width="0.99" height="0.746"/>
   
	   <rect x="358.122" y="371.628" transform="matrix(0.9476 0.3195 -0.3195 0.9476 137.6299 -95.0746)" fill="#FFFFFF" width="0.993" height="0.708"/>
   <path fill="#FFFFFF" d="M365.698,374.373c0,0,0.562,0.267,1.401,0.667c0.42,0.199,0.91,0.434,1.437,0.684
	   c0.522,0.255,1.093,0.5,1.636,0.813c1.097,0.598,2.192,1.194,3.017,1.642c0.827,0.452,1.348,0.781,1.347,0.781l-0.33,0.56
	   c0-0.001-0.53-0.334-1.34-0.771c-0.813-0.439-1.899-1.024-2.984-1.61c-0.534-0.307-1.108-0.552-1.632-0.805
	   c-0.525-0.249-1.019-0.48-1.438-0.679c-0.842-0.397-1.403-0.662-1.403-0.662L365.698,374.373z"/>
   
	   <rect x="375.145" y="379.697" transform="matrix(0.838 0.5457 -0.5457 0.838 268.2451 -143.4196)" fill="#FFFFFF" width="0.994" height="0.642"/>
   
	   <rect x="383.602" y="386.601" transform="matrix(0.7002 0.714 -0.714 0.7002 391.3895 -158.2319)" fill="#FFFFFF" width="0.993" height="0.606"/>
   <path fill="#FFFFFF" d="M389.135,392.448c0,0,1.349,2.084,2.697,4.167c1.146,2.226,2.291,4.451,2.291,4.451l-0.495,0.227
	   c0,0-1.136-2.186-2.269-4.37c-1.354-2.079-2.713-4.158-2.713-4.158L389.135,392.448z"/>
   
	   <rect x="393.984" y="402.259" transform="matrix(0.3856 0.9227 -0.9227 0.3856 613.7722 -116.6581)" fill="#FFFFFF" width="0.993" height="0.539"/>
   
	   <rect x="397.065" y="412.717" transform="matrix(0.1772 0.9842 -0.9842 0.1772 733.5673 -51.4638)" fill="#FFFFFF" width="0.992" height="0.5"/>
   <path fill="#FFFFFF" d="M398.597,420.394c0,0-0.052,2.483-0.103,4.965c-0.301,2.48-0.6,4.963-0.6,4.963l-0.435-0.074
	   c0,0,0.287-2.449,0.574-4.898c0.043-2.48,0.085-4.963,0.085-4.963L398.597,420.394z"/>
   
	   <rect x="396.923" y="431.507" transform="matrix(-0.1997 0.9799 -0.9799 -0.1997 899.8153 128.5298)" fill="#FFFFFF" width="0.993" height="0.434"/>
   
	   <rect x="393.607" y="441.958" transform="matrix(-0.4091 0.9125 -0.9125 -0.4091 958.7856 263.4007)" fill="#FFFFFF" width="0.993" height="0.397"/>
   <path fill="#FFFFFF" d="M390.696,448.787c0,0-0.369,0.5-0.921,1.25c-0.139,0.186-0.288,0.391-0.446,0.604
	   c-0.147,0.223-0.353,0.424-0.539,0.648c-0.387,0.441-0.798,0.914-1.209,1.387c-0.444,0.439-0.893,0.875-1.311,1.285
	   c-0.406,0.42-0.83,0.768-1.192,1.064c-0.72,0.598-1.199,0.996-1.199,0.996l-0.205-0.268c0,0,0.474-0.396,1.184-0.99
	   c0.357-0.295,0.775-0.639,1.176-1.057c0.412-0.406,0.852-0.84,1.29-1.273c0.402-0.465,0.807-0.932,1.184-1.367
	   c0.185-0.223,0.381-0.42,0.53-0.643c0.158-0.215,0.308-0.418,0.443-0.605c0.551-0.75,0.918-1.252,0.918-1.252L390.696,448.787z"/>
   
	   <rect x="382.078" y="456.603" transform="matrix(-0.8222 0.5692 -0.5692 -0.8222 957.1176 614.5551)" fill="#FFFFFF" width="0.988" height="0.33"/>
   
	   <rect x="372.191" y="461.118" transform="matrix(-0.9688 0.2479 -0.2479 -0.9688 848.0731 815.7632)" fill="#FFFFFF" width="0.993" height="0.292"/>
   <path fill="#FFFFFF" d="M365.331,462.406c0,0-0.619-0.02-1.55-0.047c-0.466-0.012-1.008-0.02-1.59-0.102
	   c-0.581-0.064-1.201-0.133-1.821-0.203c-0.61-0.117-1.224-0.234-1.796-0.346c-0.285-0.063-0.566-0.094-0.822-0.176
	   c-0.259-0.072-0.502-0.141-0.728-0.203c-0.898-0.25-1.499-0.418-1.499-0.418l0.071-0.221c0,0,0.596,0.162,1.489,0.408
	   c0.224,0.063,0.465,0.129,0.721,0.199c0.254,0.08,0.534,0.111,0.817,0.17c0.567,0.109,1.176,0.225,1.781,0.338
	   c0.613,0.066,1.228,0.133,1.802,0.193c0.571,0.08,1.114,0.088,1.58,0.098c0.932,0.023,1.552,0.039,1.552,0.039L365.331,462.406z"
	   />
   
	   <rect x="353.662" y="460.17" transform="matrix(-0.9352 -0.354 0.354 -0.9352 522.4257 1016.1379)" fill="#FFFFFF" width="0.993" height="0.226"/>
   
	   <rect x="344.54" y="454.392" transform="matrix(-0.6739 -0.7388 0.7388 -0.6739 241.8049 1015.6925)" fill="#FFFFFF" width="0.994" height="0.189"/>
   <path fill="#FFFFFF" d="M341.105,448.191c0,0-0.174-0.598-0.434-1.488c-0.065-0.225-0.137-0.467-0.211-0.723
	   c-0.082-0.254-0.098-0.541-0.151-0.826c-0.089-0.578-0.184-1.195-0.279-1.813c-0.016-0.623-0.029-1.246-0.045-1.83
	   c0.005-0.291-0.041-0.574,0.006-0.84c0.027-0.266,0.055-0.516,0.079-0.748c0.1-0.928,0.165-1.547,0.165-1.547l0.126,0.021
	   c0,0-0.063,0.613-0.158,1.535c-0.023,0.23-0.05,0.48-0.075,0.744c-0.046,0.266,0.001,0.545-0.003,0.834
	   c0.017,0.58,0.032,1.199,0.051,1.816c0.097,0.607,0.192,1.217,0.282,1.789c0.056,0.283,0.067,0.563,0.151,0.816
	   c0.076,0.258,0.147,0.498,0.213,0.723c0.265,0.893,0.438,1.486,0.438,1.486L341.105,448.191z"/>
   
	   <rect x="340.115" y="436.902" transform="matrix(0.2504 -0.9681 0.9681 0.2504 -167.7235 657.2989)" fill="#FFFFFF" width="0.991" height="0.121"/>
   
	   <rect x="346.014" y="427.996" transform="matrix(0.8463 -0.5327 0.5327 0.8463 -174.7657 250.3956)" fill="#FFFFFF" width="0.994" height="0.083"/>
   <path fill="#FFFFFF" d="M353.593,426.203c0,0,0.151,0.02,0.422,0.053c0.269,0.047,0.659,0.059,1.112,0.168
	   c0.449,0.123,0.994,0.203,1.532,0.434c0.272,0.102,0.56,0.197,0.841,0.314c0.273,0.139,0.552,0.279,0.829,0.418
	   c0.285,0.127,0.544,0.305,0.791,0.482c0.244,0.182,0.518,0.32,0.714,0.541c0.211,0.203,0.413,0.398,0.606,0.584
	   c0.175,0.201,0.317,0.41,0.461,0.594c0.306,0.357,0.454,0.723,0.591,0.957c0.129,0.24,0.201,0.377,0.201,0.377l-0.021,0.01
	   c0,0-0.073-0.137-0.202-0.375c-0.137-0.232-0.286-0.598-0.592-0.951c-0.145-0.182-0.286-0.391-0.463-0.59
	   c-0.19-0.184-0.395-0.377-0.604-0.578c-0.195-0.217-0.47-0.355-0.714-0.537c-0.25-0.18-0.504-0.352-0.789-0.479
	   c-0.277-0.139-0.555-0.277-0.827-0.412c-0.281-0.117-0.566-0.211-0.839-0.313c-0.537-0.229-1.078-0.303-1.525-0.424
	   c-0.449-0.109-0.844-0.117-1.109-0.162c-0.271-0.033-0.424-0.055-0.424-0.055L353.593,426.203z"/>
   
	   <rect x="361.622" y="432.524" transform="matrix(0.2105 0.9776 -0.9776 0.2105 708.7482 -12.5098)" fill="#FFFFFF" width="0.993" height="0.017"/>
   <polygon fill="#FFFFFF" points="264.555,492.062 262.621,482.326 263.77,482.095 265.736,491.826 		"/>
   
	   <rect x="262.489" y="480.151" transform="matrix(-0.1458 -0.9893 0.9893 -0.1458 -174.2571 811.0129)" fill="#FFFFFF" width="0.991" height="1.164"/>
   
	   <rect x="261.312" y="469.296" transform="matrix(-0.0629 -0.998 0.998 -0.0629 -190.6613 760.6887)" fill="#FFFFFF" width="0.994" height="1.125"/>
   <path fill="#FFFFFF" d="M260.984,462.437c0,0-0.001-0.152-0.001-0.426c-0.001-0.137-0.001-0.301-0.002-0.49
	   c0.004-0.193,0.008-0.412,0.013-0.65c0.025-0.934,0.063-2.174,0.098-3.414c0.003-0.619,0.075-1.248,0.119-1.832
	   c0.048-0.586,0.093-1.133,0.132-1.602c0.077-0.938,0.129-1.564,0.129-1.564l1.061,0.115c0,0-0.048,0.613-0.12,1.533
	   c-0.036,0.459-0.079,0.994-0.124,1.568c-0.041,0.576-0.11,1.186-0.111,1.809c-0.031,1.24-0.063,2.482-0.086,3.414
	   c-0.003,0.227-0.006,0.434-0.009,0.617c0.001,0.189,0.002,0.355,0.003,0.49c0.002,0.271,0.003,0.426,0.003,0.426L260.984,462.437z
	   "/>
   
	   <rect x="261.669" y="450.493" transform="matrix(0.1272 -0.9919 0.9919 0.1272 -218.5406 653.6924)" fill="#FFFFFF" width="0.99" height="1.059"/>
   
	   <rect x="263.778" y="439.807" transform="matrix(0.261 -0.9653 0.9653 0.261 -229.7621 580.4921)" fill="#FFFFFF" width="0.991" height="1.021"/>
   <path fill="#FFFFFF" d="M266.078,433.041c0,0,0.938-2.299,1.877-4.596c1.088-2.256,2.176-4.514,2.176-4.514l0.854,0.443
	   c0,0-1.062,2.219-2.122,4.438c-0.931,2.301-1.86,4.604-1.86,4.604L266.078,433.041z"/>
   
	   <rect x="270.787" y="422.359" transform="matrix(0.4784 -0.8781 0.8781 0.4784 -229.8108 458.7546)" fill="#FFFFFF" width="0.992" height="0.954"/>
   
	   <rect x="276.596" y="413.157" transform="matrix(0.5918 -0.8061 0.8061 0.5918 -220.3011 392.2051)" fill="#FFFFFF" width="0.993" height="0.919"/>
   <path fill="#FFFFFF" d="M281.443,407.517c0,0,1.703-1.806,3.407-3.61c1.825-1.712,3.65-3.425,3.65-3.425l0.567,0.644
	   c0,0-1.79,1.69-3.581,3.38c-1.696,1.813-3.395,3.623-3.395,3.623L281.443,407.517z"/>
   
	   <rect x="289.421" y="399.397" transform="matrix(0.7623 -0.6472 0.6472 0.7623 -189.8605 282.677)" fill="#FFFFFF" width="0.993" height="0.851"/>
   
	   <rect x="298.148" y="392.905" transform="matrix(0.8418 -0.5399 0.5399 0.8418 -165.0743 223.4671)" fill="#FFFFFF" width="0.993" height="0.813"/>
   <path fill="#FFFFFF" d="M304.941,389.232c0,0,2.238-1.072,4.479-2.144c2.315-0.938,4.635-1.878,4.635-1.878l0.262,0.707
	   c0,0-2.281,0.934-4.563,1.865c-2.236,1.079-4.472,2.158-4.472,2.158L304.941,389.232z"/>
   
	   <rect x="315.078" y="384.677" transform="matrix(0.9445 -0.3286 0.3286 0.9445 -109.0027 125.0786)" fill="#FFFFFF" width="0.993" height="0.747"/>
   
	   <rect x="325.631" y="381.819" transform="matrix(0.9802 -0.1978 0.1978 0.9802 -69.1565 72.0666)" fill="#FFFFFF" width="0.992" height="0.71"/>
   <path fill="#FFFFFF" d="M333.438,380.68c0,0,0.618-0.049,1.547-0.123c0.464-0.037,1.006-0.079,1.585-0.126
	   c0.581-0.04,1.196-0.112,1.823-0.113c1.248-0.031,2.496-0.063,3.433-0.087c0.943-0.021,1.559,0.002,1.559,0.002l-0.007,0.65
	   c0,0-0.627-0.022-1.547,0.003c-0.925,0.026-2.156,0.063-3.391,0.097c-0.616,0.002-1.235,0.077-1.814,0.119
	   c-0.58,0.049-1.121,0.094-1.586,0.132c-0.928,0.077-1.546,0.128-1.546,0.128L333.438,380.68z"/>
   
	   <rect x="344.377" y="380.28" transform="matrix(0.9986 0.0531 -0.0531 0.9986 20.6864 -17.7677)" fill="#FFFFFF" width="0.994" height="0.642"/>
   
	   <rect x="355.13" y="382.06" transform="matrix(0.963 0.2695 -0.2695 0.963 116.2145 -81.6992)" fill="#FFFFFF" width="0.993" height="0.604"/>
   <path fill="#FFFFFF" d="M362.771,384.618c0,0,2.21,1.13,4.42,2.259c2.104,1.355,4.21,2.709,4.21,2.709l-0.316,0.444
	   c0,0-2.074-1.326-4.147-2.651c-2.215-1.122-4.429-2.244-4.429-2.244L362.771,384.618z"/>
   
	   <rect x="371.945" y="390.431" transform="matrix(0.796 0.6053 -0.6053 0.796 312.4915 -145.7375)" fill="#FFFFFF" width="0.994" height="0.538"/>
   
	   <rect x="379.856" y="397.931" transform="matrix(0.645 0.7642 -0.7642 0.645 439.2976 -149.3054)" fill="#FFFFFF" width="0.992" height="0.5"/>
   <path fill="#FFFFFF" d="M384.936,404.088c0,0,1.196,2.176,2.394,4.352c0.981,2.298,1.964,4.598,1.964,4.598l-0.414,0.153
	   c0,0-0.976-2.266-1.949-4.529c-1.205-2.171-2.409-4.342-2.409-4.342L384.936,404.088z"/>
   
	   <rect x="389.088" y="414.296" transform="matrix(0.3177 0.9482 -0.9482 0.3177 658.85 -86.5793)" fill="#FFFFFF" width="0.993" height="0.435"/>
   
	   <rect x="391.434" y="424.968" transform="matrix(0.1024 0.9947 -0.9947 0.1024 774.714 -8.2516)" fill="#FFFFFF" width="0.992" height="0.396"/>
   <path fill="#FFFFFF" d="M392.288,432.628c0,0-0.068,0.617-0.173,1.541c-0.024,0.232-0.055,0.482-0.083,0.748
	   c-0.02,0.266-0.093,0.543-0.144,0.832c-0.112,0.576-0.232,1.189-0.354,1.805c-0.168,0.604-0.335,1.203-0.491,1.768
	   c-0.143,0.568-0.335,1.08-0.503,1.518c-0.323,0.879-0.541,1.465-0.541,1.465l-0.311-0.129c0,0,0.213-0.58,0.53-1.449
	   c0.163-0.434,0.353-0.939,0.49-1.502c0.152-0.559,0.316-1.152,0.479-1.75c0.116-0.604,0.233-1.207,0.341-1.773
	   c0.049-0.285,0.121-0.555,0.14-0.82c0.028-0.266,0.057-0.516,0.081-0.748c0.102-0.926,0.169-1.543,0.169-1.543L392.288,432.628z"
	   />
   
	   <rect x="388.741" y="443.406" transform="matrix(-0.4273 0.9041 -0.9041 -0.4273 956.5957 281.2103)" fill="#FFFFFF" width="0.987" height="0.329"/>
   
	   <rect x="382.475" y="452.311" transform="matrix(-0.7149 0.6992 -0.6992 -0.7149 973.1226 508.1671)" fill="#FFFFFF" width="0.992" height="0.29"/>
   <path fill="#FFFFFF" d="M377.132,457.103c0,0-0.546,0.295-1.365,0.736c-0.408,0.223-0.883,0.486-1.428,0.705
	   c-0.535,0.236-1.107,0.486-1.68,0.736c-0.589,0.203-1.178,0.406-1.729,0.598c-0.278,0.09-0.538,0.203-0.8,0.26
	   c-0.261,0.066-0.504,0.129-0.73,0.188c-0.904,0.232-1.508,0.387-1.508,0.387l-0.049-0.227c0,0,0.598-0.156,1.495-0.391
	   c0.224-0.059,0.467-0.121,0.724-0.188c0.261-0.059,0.518-0.172,0.793-0.262c0.547-0.189,1.13-0.395,1.714-0.6
	   c0.563-0.248,1.127-0.498,1.656-0.732c0.534-0.217,1.008-0.48,1.416-0.707c0.817-0.443,1.363-0.74,1.363-0.74L377.132,457.103z"/>
   
	   <rect x="365.896" y="460.756" transform="matrix(-0.9869 0.1612 -0.1612 -0.9869 802.2889 856.6431)" fill="#FFFFFF" width="0.993" height="0.226"/>
   
	   <rect x="355.094" y="460.296" transform="matrix(-0.953 -0.3029 0.3029 -0.953 555.0132 1006.8628)" fill="#FFFFFF" width="0.993" height="0.187"/>
   <path fill="#FFFFFF" d="M349.043,456.904c0,0-0.447-0.43-1.12-1.072c-0.167-0.16-0.351-0.336-0.543-0.52
	   c-0.197-0.18-0.354-0.422-0.544-0.643c-0.366-0.455-0.758-0.943-1.146-1.428c-0.325-0.531-0.65-1.064-0.955-1.563
	   c-0.142-0.254-0.321-0.479-0.415-0.73c-0.107-0.244-0.211-0.475-0.305-0.689c-0.379-0.854-0.631-1.422-0.631-1.422l0.12-0.043
	   c0,0,0.252,0.564,0.632,1.41c0.094,0.211,0.195,0.439,0.306,0.684c0.093,0.25,0.273,0.471,0.415,0.723
	   c0.303,0.494,0.627,1.021,0.951,1.547c0.388,0.479,0.774,0.959,1.139,1.408c0.189,0.219,0.342,0.455,0.542,0.635
	   c0.192,0.184,0.375,0.355,0.544,0.518c0.674,0.641,1.124,1.068,1.124,1.068L349.043,456.904z"/>
   
	   <rect x="342.513" y="447.34" transform="matrix(-0.2698 -0.9629 0.9629 -0.2698 4.731 898.3915)" fill="#FFFFFF" width="0.992" height="0.124"/>
   
	   <rect x="343.114" y="436.704" transform="matrix(0.4668 -0.8844 0.8844 0.4668 -203.0238 536.7748)" fill="#FFFFFF" width="0.994" height="0.083"/>
   <path fill="#FFFFFF" d="M348.867,431.621c0,0,0.144-0.063,0.394-0.166c0.255-0.094,0.598-0.281,1.047-0.412
	   c0.451-0.117,0.962-0.322,1.543-0.393c0.288-0.047,0.584-0.107,0.888-0.146c0.306-0.018,0.615-0.035,0.926-0.053
	   c0.312-0.033,0.623-0.008,0.927,0.021c0.303,0.037,0.607,0.021,0.889,0.113c0.283,0.07,0.558,0.137,0.817,0.201
	   c0.252,0.088,0.479,0.197,0.695,0.285c0.442,0.156,0.754,0.398,0.989,0.533c0.23,0.145,0.363,0.227,0.363,0.227l-0.016,0.018
	   c0,0-0.132-0.08-0.361-0.223c-0.234-0.133-0.546-0.373-0.987-0.529c-0.215-0.084-0.443-0.193-0.694-0.279
	   c-0.258-0.063-0.529-0.129-0.813-0.197c-0.277-0.09-0.584-0.076-0.887-0.109c-0.307-0.029-0.612-0.053-0.923-0.018
	   c-0.31,0.018-0.617,0.037-0.922,0.053c-0.303,0.041-0.597,0.104-0.883,0.15c-0.579,0.072-1.086,0.275-1.534,0.395
	   c-0.444,0.133-0.789,0.32-1.043,0.414c-0.251,0.107-0.394,0.168-0.394,0.168L348.867,431.621z"/>
   
	   <rect x="358.943" y="432.833" transform="matrix(0.6708 0.7416 -0.7416 0.6708 439.3313 -124.0836)" fill="#FFFFFF" width="0.993" height="0.016"/>
   
	   <rect x="278.115" y="487.998" transform="matrix(-0.2569 -0.9664 0.9664 -0.2569 -121.9408 883.2542)" fill="#FFFFFF" width="0.992" height="1.022"/>
   <path fill="#FFFFFF" d="M276.495,481.298c0,0-0.337-2.459-0.674-4.918c-0.187-2.498-0.371-4.996-0.371-4.996l0.961-0.043
	   c0,0,0.189,2.451,0.382,4.904c0.345,2.459,0.688,4.916,0.688,4.916L276.495,481.298z"/>
   
	   <rect x="275.388" y="469.409" transform="matrix(-0.0246 -0.9997 0.9997 -0.0246 -187.0731 757.246)" fill="#FFFFFF" width="0.992" height="0.955"/>
   
	   <rect x="275.84" y="458.516" transform="matrix(0.1104 -0.9939 0.9939 0.1104 -210.3488 682.9348)" fill="#FFFFFF" width="0.99" height="0.918"/>
   <path fill="#FFFFFF" d="M277.038,451.511c0,0,0.572-2.414,1.146-4.83c0.726-2.396,1.449-4.791,1.449-4.791l0.813,0.271
	   c0,0-0.705,2.359-1.41,4.719c-0.564,2.418-1.128,4.836-1.128,4.836L277.038,451.511z"/>
   
	   <rect x="280.01" y="440.175" transform="matrix(0.336 -0.9419 0.9419 0.336 -228.725 556.7773)" fill="#FFFFFF" width="0.991" height="0.852"/>
   
	   <rect x="284.372" y="430.171" transform="matrix(0.4598 -0.888 0.888 0.4598 -228.4749 485.5844)" fill="#FFFFFF" width="0.992" height="0.812"/>
   <path fill="#FFFFFF" d="M288.245,423.927c0,0,1.402-2.047,2.808-4.094c1.537-1.972,3.075-3.943,3.075-3.943l0.58,0.481
	   c0,0-1.509,1.948-3.02,3.896c-1.396,2.053-2.793,4.104-2.793,4.104L288.245,423.927z"/>
   
	   <rect x="294.89" y="414.641" transform="matrix(0.6535 -0.7569 0.7569 0.6535 -211.7811 367.3595)" fill="#FFFFFF" width="0.992" height="0.747"/>
   
	   <rect x="302.562" y="406.865" transform="matrix(0.75 -0.6614 0.6614 0.75 -193.5844 302.2513)" fill="#FFFFFF" width="0.992" height="0.708"/>
   <path fill="#FFFFFF" d="M308.648,402.272c0,0,0.511-0.353,1.278-0.88c0.383-0.264,0.83-0.571,1.31-0.901
	   c0.483-0.325,0.979-0.695,1.521-1.009c1.064-0.652,2.131-1.305,2.93-1.793c0.806-0.489,1.351-0.776,1.351-0.775l0.318,0.564
	   c0,0-0.554,0.296-1.337,0.774c-0.787,0.486-1.838,1.133-2.889,1.781c-0.531,0.309-1.031,0.685-1.513,1.01
	   c-0.479,0.332-0.924,0.642-1.307,0.907c-0.766,0.53-1.275,0.884-1.275,0.884L308.648,402.272z"/>
   
	   <rect x="318.01" y="396.157" transform="matrix(0.8919 -0.4523 0.4523 0.8919 -144.8914 186.9461)" fill="#FFFFFF" width="0.992" height="0.642"/>
   
	   <rect x="328.194" y="392.316" transform="matrix(0.9686 -0.2488 0.2488 0.9686 -87.3407 94.1144)" fill="#FFFFFF" width="0.993" height="0.604"/>
   <path fill="#FFFFFF" d="M336.021,391.018c0,0,2.479-0.127,4.958-0.254c2.499,0.121,4.999,0.242,4.999,0.242l-0.052,0.542
	   c0,0-2.459-0.11-4.918-0.223c-2.479,0.136-4.957,0.271-4.957,0.271L336.021,391.018z"/>
   
	   <rect x="346.924" y="391.193" transform="matrix(0.992 0.1266 -0.1266 0.992 52.3459 -40.8277)" fill="#FFFFFF" width="0.991" height="0.538"/>
   
	   <rect x="357.534" y="393.759" transform="matrix(0.9406 0.3395 -0.3395 0.9406 155.0051 -98.1389)" fill="#FFFFFF" width="0.993" height="0.499"/>
   <path fill="#FFFFFF" d="M364.951,396.795c0,0,2.124,1.286,4.248,2.57c1.998,1.501,3.998,3.001,3.998,3.001l-0.281,0.339
	   c0,0-1.978-1.474-3.952-2.946c-2.13-1.277-4.259-2.556-4.259-2.556L364.951,396.795z"/>
   
	   <rect x="373.701" y="403.271" transform="matrix(0.7491 0.6625 -0.6625 0.7491 361.1877 -146.6547)" fill="#FFFFFF" width="0.992" height="0.434"/>
   
	   <rect x="381.03" y="411.394" transform="matrix(0.5865 0.8099 -0.8099 0.5865 491.1083 -138.8281)" fill="#FFFFFF" width="0.992" height="0.396"/>
   <path fill="#FFFFFF" d="M385.591,417.834c0,0,0.248,0.569,0.621,1.423c0.094,0.213,0.194,0.444,0.302,0.688
	   c0.116,0.238,0.189,0.517,0.29,0.792c0.19,0.555,0.395,1.147,0.598,1.739c0.156,0.605,0.313,1.209,0.458,1.777
	   c0.161,0.563,0.249,1.104,0.325,1.564c0.156,0.924,0.263,1.537,0.263,1.537l-0.335,0.045c0,0-0.104-0.607-0.265-1.521
	   c-0.075-0.457-0.165-0.99-0.327-1.547c-0.146-0.561-0.303-1.156-0.457-1.754c-0.202-0.582-0.404-1.163-0.593-1.708
	   c-0.101-0.271-0.171-0.54-0.29-0.78c-0.107-0.244-0.209-0.476-0.303-0.688c-0.377-0.853-0.627-1.42-0.627-1.42L385.591,417.834z"
	   />
   
	   <rect x="387.936" y="428.697" transform="matrix(0.0811 0.9967 -0.9967 0.0811 784.3839 6.9377)" fill="#FFFFFF" width="0.988" height="0.328"/>
   
	   <rect x="386.891" y="439.539" transform="matrix(-0.2684 0.9633 -0.9633 -0.2684 914.9019 184.4978)" fill="#FFFFFF" width="0.993" height="0.292"/>
   <path fill="#FFFFFF" d="M384.699,446.609c0,0-0.326,0.527-0.813,1.318c-0.241,0.398-0.521,0.863-0.882,1.326
	   c-0.348,0.471-0.718,0.973-1.087,1.479c-0.408,0.469-0.815,0.939-1.197,1.383c-0.197,0.215-0.365,0.443-0.564,0.623
	   c-0.19,0.188-0.371,0.363-0.538,0.527c-0.667,0.654-1.111,1.09-1.111,1.09l-0.156-0.174c0,0,0.439-0.434,1.1-1.084
	   c0.165-0.162,0.345-0.34,0.533-0.525c0.197-0.18,0.363-0.406,0.557-0.621c0.378-0.439,0.78-0.906,1.185-1.375
	   c0.364-0.498,0.728-0.996,1.068-1.463c0.354-0.455,0.633-0.922,0.874-1.32c0.484-0.793,0.81-1.322,0.81-1.322L384.699,446.609z"/>
   
	   <rect x="376.801" y="455.13" transform="matrix(-0.7749 0.6321 -0.6321 -0.7749 957.4196 569.5046)" fill="#FFFFFF" width="0.992" height="0.224"/>
   
	   <rect x="367.168" y="460.139" transform="matrix(-0.9768 0.2142 -0.2142 -0.9768 825.3872 831.0186)" fill="#FFFFFF" width="0.994" height="0.188"/>
   <path fill="#FFFFFF" d="M360.276,460.482c0,0-0.602-0.148-1.506-0.369c-0.226-0.055-0.471-0.115-0.73-0.18
	   c-0.261-0.055-0.517-0.186-0.792-0.281c-0.545-0.211-1.127-0.438-1.708-0.664c-0.547-0.297-1.094-0.596-1.605-0.873
	   c-0.25-0.15-0.519-0.254-0.725-0.426c-0.217-0.158-0.422-0.309-0.609-0.443c-0.755-0.551-1.257-0.918-1.257-0.918l0.081-0.098
	   c0,0,0.502,0.361,1.253,0.904c0.188,0.137,0.391,0.283,0.605,0.439c0.205,0.17,0.473,0.27,0.722,0.418
	   c0.51,0.275,1.054,0.57,1.597,0.863c0.576,0.223,1.152,0.441,1.69,0.648c0.273,0.096,0.523,0.227,0.785,0.279
	   c0.26,0.063,0.505,0.121,0.73,0.176c0.904,0.219,1.508,0.363,1.508,0.363L360.276,460.482z"/>
   
	   <rect x="349.763" y="455.199" transform="matrix(-0.7135 -0.7007 0.7007 -0.7135 281.1691 1025.4954)" fill="#FFFFFF" width="0.992" height="0.12"/>
   
	   <rect x="345.028" y="445.712" transform="matrix(-0.0374 -0.9993 0.9993 -0.0374 -87.0032 807.6969)" fill="#FFFFFF" width="0.993" height="0.082"/>
   <path fill="#FFFFFF" d="M347.48,438.675c0,0,0.094-0.125,0.258-0.342c0.173-0.207,0.378-0.541,0.701-0.877
	   c0.332-0.328,0.673-0.762,1.14-1.113c0.228-0.184,0.452-0.385,0.694-0.568c0.257-0.168,0.519-0.34,0.777-0.51
	   c0.252-0.184,0.534-0.318,0.813-0.443c0.28-0.121,0.536-0.287,0.825-0.35c0.281-0.08,0.552-0.158,0.809-0.232
	   c0.263-0.053,0.516-0.068,0.746-0.104c0.461-0.086,0.854-0.029,1.124-0.031c0.271,0.01,0.428,0.014,0.428,0.014l-0.003,0.021
	   c0,0-0.154-0.004-0.427-0.01c-0.271,0.002-0.66-0.051-1.119,0.035c-0.229,0.033-0.481,0.053-0.741,0.104
	   c-0.256,0.074-0.523,0.152-0.803,0.234c-0.285,0.061-0.543,0.23-0.822,0.35c-0.279,0.127-0.557,0.262-0.808,0.445
	   c-0.258,0.17-0.518,0.342-0.771,0.51c-0.24,0.186-0.465,0.387-0.689,0.57c-0.464,0.354-0.801,0.783-1.13,1.109
	   c-0.319,0.336-0.523,0.672-0.697,0.881c-0.162,0.215-0.257,0.34-0.257,0.34L347.48,438.675z"/>
   
	   <rect x="356.753" y="434.42" transform="matrix(0.9523 0.3053 -0.3053 0.9523 149.6744 -88.3215)" fill="#FFFFFF" width="0.992" height="0.019"/>
   <path fill="#FFFFFF" d="M295.683,493.072c0.313-0.029,0.631-0.033,0.948-0.014c-0.679-1.883-1.294-3.783-1.831-5.701
	   c-1.653-5.861-2.551-11.865-2.841-17.822c-0.272-5.959,0.085-11.891,1.112-17.633c1.025-5.744,2.665-11.305,4.836-16.561
	   c2.177-5.262,4.817-10.229,8.11-14.685c3.239-4.441,7.342-8.159,11.75-11.146l1.65-1.13c0.566-0.344,1.159-0.646,1.736-0.972
	   l1.737-0.956l0.867-0.473l0.904-0.392c1.208-0.513,2.401-1.053,3.607-1.537c1.239-0.398,2.473-0.797,3.701-1.19
	   c4.975-1.378,10.042-1.984,15.005-1.915c4.967,0.18,9.769,1.105,14.304,2.522c2.197,0.896,4.454,1.598,6.478,2.751
	   c1.019,0.546,2.096,1.007,3.049,1.614l1.446,0.932l0.72,0.465l0.663,0.523c3.583,2.729,6.467,6.196,8.473,9.97
	   c2.016,3.777,3.172,7.861,3.511,11.878c0.344,4.02-0.134,7.969-1.257,11.563c-0.108,0.461-0.271,0.9-0.449,1.334
	   c-0.169,0.436-0.337,0.867-0.505,1.299c-0.297,0.881-0.762,1.676-1.164,2.488c-0.746,1.65-1.848,3.104-2.961,4.42
	   c-2.354,2.574-5.28,4.418-8.344,5.354c-3.046,1-6.187,1.113-9.033,0.609c-2.847-0.516-5.452-1.598-7.52-3.139
	   c-1.027-0.775-1.883-1.715-2.545-2.725c-0.664-1.006-1.188-2.066-1.49-3.15c-1.294-4.367,0.103-8.496,2.191-10.732
	   c1.094-1.111,2.396-1.719,3.546-1.971c1.159-0.264,2.181-0.211,2.967-0.059c0.79,0.15,1.358,0.389,1.729,0.559
	   c0.363,0.186,0.55,0.281,0.55,0.281s-0.186-0.098-0.549-0.283c-0.369-0.172-0.938-0.41-1.729-0.564
	   c-0.786-0.154-1.81-0.213-2.971,0.047c-1.155,0.25-2.465,0.855-3.566,1.967c-2.107,2.24-3.523,6.379-2.239,10.775
	   c0.302,1.094,0.824,2.164,1.491,3.182c0.664,1.018,1.52,1.965,2.557,2.756c2.076,1.557,4.69,2.652,7.556,3.184
	   c2.864,0.52,6.029,0.414,9.104-0.582c3.091-0.934,6.051-2.783,8.439-5.375c1.146-1.348,2.234-2.771,3.005-4.451
	   c0.408-0.816,0.88-1.619,1.183-2.502c0.171-0.436,0.341-0.871,0.514-1.309c0.182-0.438,0.346-0.877,0.457-1.34
	   c1.145-3.621,1.641-7.602,1.309-11.658c-0.328-4.053-1.48-8.18-3.503-12.002c-2.021-3.824-4.906-7.33-8.545-10.119l-0.673-0.536
	   l-0.72-0.466l-1.441-0.938c-0.984-0.632-2.048-1.089-3.079-1.646c-2.034-1.168-4.306-1.884-6.521-2.795
	   c-4.567-1.445-9.407-2.398-14.422-2.597c-5.01-0.09-10.13,0.508-15.16,1.882c-1.241,0.395-2.489,0.792-3.742,1.189
	   c-1.223,0.486-2.431,1.026-3.653,1.541l-0.917,0.393l-0.878,0.476l-1.76,0.961c-0.585,0.326-1.187,0.63-1.762,0.978l-1.674,1.137
	   c-4.476,3.015-8.621,6.734-11.946,11.261c-3.32,4.458-6.027,9.5-8.231,14.782c-2.207,5.295-3.881,10.896-4.937,16.691
	   c-1.06,5.795-1.44,11.781-1.188,17.803c0.271,6.018,1.157,12.088,2.809,18.018C294.463,489.431,295.044,491.259,295.683,493.072z"
	   />
   <path fill="#FFFFFF" d="M312.835,494.128c0,0-1.071-2.238-2.144-4.479c-0.938-2.316-1.877-4.635-1.877-4.635l0.706-0.264
	   c0,0,0.934,2.283,1.866,4.564c1.079,2.234,2.158,4.471,2.158,4.471L312.835,494.128z"/>
   
	   <rect x="308.155" y="483.133" transform="matrix(-0.3301 -0.944 0.944 -0.3301 -45.8745 934.456)" fill="#FFFFFF" width="0.991" height="0.747"/>
   
	   <rect x="305.285" y="472.585" transform="matrix(-0.1978 -0.9802 0.9802 -0.1978 -97.3235 866.2349)" fill="#FFFFFF" width="0.992" height="0.709"/>
   <path fill="#FFFFFF" d="M304.283,465.632c0,0-0.05-0.619-0.123-1.547c-0.037-0.463-0.079-1.006-0.125-1.586
	   c-0.041-0.58-0.113-1.195-0.114-1.822c-0.031-1.248-0.063-2.496-0.087-3.434c-0.021-0.941,0.002-1.559,0.002-1.559l0.649,0.01
	   c0,0-0.021,0.625,0.003,1.545c0.026,0.924,0.063,2.156,0.099,3.391c0.002,0.615,0.076,1.234,0.119,1.814
	   c0.048,0.58,0.093,1.121,0.131,1.586c0.077,0.928,0.128,1.547,0.128,1.547L304.283,465.632z"/>
   
	   <rect x="303.723" y="453.897" transform="matrix(0.0531 -0.9986 0.9986 0.0531 -165.5035 733.901)" fill="#FFFFFF" width="0.994" height="0.641"/>
   
	   <rect x="305.487" y="443.119" transform="matrix(0.2684 -0.9633 0.9633 0.2684 -203.2851 619.188)" fill="#FFFFFF" width="0.993" height="0.606"/>
   <path fill="#FFFFFF" d="M308.221,436.298c0,0,1.131-2.209,2.261-4.42c1.354-2.104,2.708-4.209,2.708-4.209l0.443,0.316
	   c0,0-1.325,2.074-2.65,4.148c-1.122,2.213-2.244,4.428-2.244,4.428L308.221,436.298z"/>
   
	   <rect x="313.79" y="426.37" transform="matrix(0.6069 -0.7948 0.7948 0.6069 -215.5374 417.4987)" fill="#FFFFFF" width="0.993" height="0.538"/>
   
	   <rect x="321.288" y="418.465" transform="matrix(0.7637 -0.6456 0.6456 0.7637 -194.276 306.6891)" fill="#FFFFFF" width="0.992" height="0.501"/>
   <path fill="#FFFFFF" d="M327.69,414.133c0,0,2.176-1.197,4.352-2.394c2.299-0.981,4.598-1.963,4.598-1.963l0.153,0.413
	   c0,0-2.266,0.976-4.529,1.95c-2.171,1.205-4.341,2.409-4.341,2.409L327.69,414.133z"/>
   
	   <rect x="337.62" y="409.266" transform="matrix(0.9482 -0.3177 0.3177 0.9482 -112.5763 128.6357)" fill="#FFFFFF" width="0.993" height="0.434"/>
   
	   <rect x="348.258" y="406.947" transform="matrix(0.9947 -0.1033 0.1033 0.9947 -40.1931 38.2052)" fill="#FFFFFF" width="0.993" height="0.397"/>
   <path fill="#FFFFFF" d="M356.23,406.777c0,0,0.617,0.068,1.542,0.174c0.232,0.024,0.482,0.053,0.748,0.083
	   c0.265,0.019,0.543,0.093,0.831,0.144c0.576,0.112,1.189,0.232,1.806,0.354c0.603,0.167,1.203,0.334,1.768,0.491
	   c0.568,0.143,1.08,0.335,1.518,0.501c0.879,0.324,1.465,0.542,1.465,0.542l-0.129,0.311c0,0-0.58-0.211-1.45-0.529
	   c-0.434-0.164-0.938-0.353-1.502-0.49c-0.559-0.152-1.152-0.316-1.748-0.48c-0.604-0.115-1.209-0.23-1.775-0.341
	   c-0.284-0.048-0.554-0.121-0.82-0.14c-0.266-0.027-0.517-0.056-0.747-0.08c-0.927-0.102-1.543-0.168-1.543-0.168L356.23,406.777z"
	   />
   
	   <rect x="366.68" y="409.667" transform="matrix(0.9041 0.4273 -0.4273 0.9041 210.3413 -117.5981)" fill="#FFFFFF" width="0.987" height="0.328"/>
   
	   <rect x="375.537" y="415.985" transform="matrix(0.7002 0.7139 -0.7139 0.7002 409.8311 -143.7125)" fill="#FFFFFF" width="0.994" height="0.291"/>
   <path fill="#FFFFFF" d="M380.705,421.937c0,0,0.295,0.547,0.735,1.363c0.224,0.408,0.486,0.883,0.707,1.428
	   c0.234,0.537,0.485,1.107,0.735,1.68c0.204,0.588,0.406,1.178,0.599,1.729c0.089,0.279,0.202,0.539,0.259,0.803
	   c0.067,0.258,0.13,0.502,0.188,0.729c0.231,0.904,0.387,1.508,0.387,1.508l-0.227,0.049c0,0-0.156-0.598-0.391-1.496
	   c-0.059-0.223-0.122-0.465-0.188-0.723c-0.058-0.26-0.171-0.518-0.261-0.793c-0.19-0.547-0.396-1.131-0.6-1.713
	   c-0.249-0.564-0.499-1.127-0.732-1.656c-0.217-0.535-0.481-1.01-0.707-1.416c-0.444-0.816-0.74-1.361-0.74-1.361L380.705,421.937z
	   "/>
   
	   <rect x="383.971" y="432.533" transform="matrix(0.1615 0.9869 -0.9869 0.1615 749.3315 -16.655)" fill="#FFFFFF" width="0.992" height="0.226"/>
   
	   <rect x="383.494" y="443.371" transform="matrix(-0.3026 0.9531 -0.9531 -0.3026 922.869 211.6883)" fill="#FFFFFF" width="0.994" height="0.187"/>
   <path fill="#FFFFFF" d="M380.51,450.025c0,0-0.431,0.447-1.072,1.119c-0.161,0.168-0.336,0.35-0.52,0.543
	   c-0.179,0.199-0.421,0.354-0.643,0.545c-0.455,0.365-0.941,0.756-1.428,1.146c-0.531,0.324-1.063,0.65-1.563,0.955
	   c-0.254,0.141-0.478,0.322-0.729,0.414c-0.244,0.109-0.476,0.211-0.688,0.307c-0.854,0.379-1.423,0.631-1.423,0.631l-0.044-0.119
	   c0,0,0.564-0.254,1.411-0.633c0.211-0.096,0.439-0.197,0.683-0.307c0.251-0.092,0.472-0.271,0.724-0.414
	   c0.494-0.305,1.021-0.629,1.546-0.951c0.479-0.389,0.959-0.775,1.409-1.141c0.219-0.189,0.455-0.342,0.634-0.541
	   c0.183-0.193,0.355-0.377,0.517-0.545c0.643-0.674,1.068-1.123,1.068-1.123L380.51,450.025z"/>
   
	   <rect x="370.487" y="455.999" transform="matrix(-0.9636 0.2674 -0.2674 -0.9636 850.4219 796.2897)" fill="#FFFFFF" width="0.993" height="0.12"/>
   
	   <rect x="359.855" y="455.42" transform="matrix(-0.8848 -0.466 0.466 -0.8848 466.9469 1026.3674)" fill="#FFFFFF" width="0.993" height="0.082"/>
   <path fill="#FFFFFF" d="M355.225,450.205c0,0-0.062-0.143-0.165-0.395c-0.094-0.254-0.281-0.598-0.411-1.047
	   c-0.118-0.449-0.323-0.963-0.393-1.543c-0.049-0.287-0.107-0.584-0.147-0.887c-0.018-0.305-0.035-0.615-0.053-0.926
	   c-0.033-0.313-0.01-0.623,0.021-0.926c0.037-0.305,0.021-0.609,0.112-0.891c0.07-0.283,0.138-0.557,0.202-0.816
	   c0.086-0.252,0.197-0.48,0.284-0.697c0.156-0.441,0.397-0.752,0.532-0.988c0.144-0.23,0.226-0.363,0.226-0.363l0.02,0.014
	   c0,0-0.081,0.133-0.223,0.363c-0.134,0.234-0.374,0.547-0.527,0.986c-0.087,0.217-0.195,0.445-0.28,0.695
	   c-0.063,0.258-0.131,0.531-0.197,0.813c-0.091,0.277-0.074,0.584-0.109,0.889c-0.029,0.307-0.053,0.609-0.018,0.92
	   c0.019,0.311,0.037,0.619,0.054,0.924c0.042,0.301,0.104,0.596,0.15,0.883c0.071,0.578,0.276,1.084,0.396,1.533
	   c0.13,0.445,0.318,0.789,0.414,1.045c0.105,0.248,0.166,0.393,0.166,0.393L355.225,450.205z"/>
   
	   <rect x="355.948" y="439.598" transform="matrix(0.7407 -0.6718 0.6718 0.7407 -202.9119 353.4266)" fill="#FFFFFF" width="0.991" height="0.017"/>
   <polygon fill="#FFFFFF" points="440.378,283.369 447.503,290.28 446.108,291.723 438.96,284.836 		"/>
   
	   <rect x="447.342" y="291.085" transform="matrix(0.6965 0.7176 -0.7176 0.6965 345.5406 -232.7087)" fill="#FFFFFF" width="0.993" height="1.999"/>
   
	   <rect x="454.742" y="299.095" transform="matrix(0.6568 0.7541 -0.7541 0.6568 382.5407 -240.2976)" fill="#FFFFFF" width="0.993" height="1.959"/>
   <path fill="#FFFFFF" d="M325.876,490.419c0,0-0.353-0.51-0.88-1.277c-0.264-0.383-0.571-0.83-0.901-1.311
	   c-0.325-0.482-0.695-0.979-1.009-1.521c-0.652-1.064-1.305-2.131-1.792-2.93c-0.491-0.805-0.777-1.35-0.776-1.35l0.564-0.318
	   c0,0,0.295,0.553,0.774,1.336c0.486,0.789,1.133,1.838,1.781,2.891c0.309,0.531,0.684,1.029,1.01,1.512
	   c0.332,0.479,0.642,0.924,0.907,1.307c0.53,0.766,0.884,1.275,0.884,1.275L325.876,490.419z"/>
   
	   <rect x="319.588" y="480.249" transform="matrix(-0.4535 -0.8913 0.8913 -0.4535 36.9313 983.7852)" fill="#FFFFFF" width="0.993" height="0.642"/>
   
	   <rect x="315.722" y="470.077" transform="matrix(-0.249 -0.9685 0.9685 -0.249 -60.6014 893.7678)" fill="#FFFFFF" width="0.992" height="0.604"/>
   <path fill="#FFFFFF" d="M314.62,463.048c0,0-0.127-2.479-0.253-4.957c0.12-2.5,0.241-5,0.241-5l0.542,0.051
	   c0,0-0.109,2.461-0.222,4.918c0.135,2.48,0.271,4.957,0.271,4.957L314.62,463.048z"/>
   
	   <rect x="314.546" y="451.376" transform="matrix(0.1277 -0.9918 0.9918 0.1277 -173.1244 706.4542)" fill="#FFFFFF" width="0.994" height="0.539"/>
   
	   <rect x="317.083" y="440.759" transform="matrix(0.3403 -0.9403 0.9403 0.3403 -205.1838 589.5466)" fill="#FFFFFF" width="0.993" height="0.499"/>
   <path fill="#FFFFFF" d="M320.4,434.117c0,0,1.285-2.123,2.569-4.248c1.501-1.998,3.001-3.998,3.001-3.998l0.339,0.281
	   c0,0-1.474,1.977-2.946,3.955c-1.278,2.127-2.556,4.256-2.556,4.256L320.4,434.117z"/>
   
	   <rect x="326.615" y="424.647" transform="matrix(0.662 -0.7495 0.7495 0.662 -207.8752 388.79)" fill="#FFFFFF" width="0.993" height="0.435"/>
   
	   <rect x="334.699" y="417.345" transform="matrix(0.8096 -0.587 0.587 0.8096 -181.2723 276.2647)" fill="#FFFFFF" width="0.992" height="0.396"/>
   <path fill="#FFFFFF" d="M341.438,413.478c0,0,0.569-0.249,1.422-0.621c0.213-0.094,0.445-0.194,0.689-0.302
	   c0.238-0.115,0.517-0.189,0.792-0.292c0.555-0.188,1.146-0.394,1.739-0.596c0.604-0.156,1.209-0.312,1.775-0.458
	   c0.564-0.161,1.104-0.25,1.565-0.325c0.923-0.158,1.537-0.263,1.537-0.263l0.045,0.334c0,0-0.607,0.105-1.521,0.266
	   c-0.457,0.075-0.989,0.165-1.547,0.326c-0.561,0.146-1.156,0.302-1.753,0.458c-0.582,0.202-1.163,0.403-1.708,0.593
	   c-0.271,0.101-0.54,0.172-0.781,0.29c-0.243,0.107-0.475,0.209-0.688,0.305c-0.853,0.376-1.421,0.626-1.421,0.626L341.438,413.478
	   z"/>
   
	   <rect x="351.97" y="410.474" transform="matrix(0.9967 -0.081 0.081 0.9967 -32.1042 29.8995)" fill="#FFFFFF" width="0.989" height="0.329"/>
   
	   <rect x="362.791" y="411.533" transform="matrix(0.963 0.2695 -0.2695 0.963 124.3994 -82.6792)" fill="#FFFFFF" width="0.993" height="0.292"/>
   <path fill="#FFFFFF" d="M370.214,414.367c0,0,0.526,0.326,1.318,0.813c0.397,0.242,0.862,0.521,1.326,0.884
	   c0.471,0.346,0.973,0.717,1.478,1.086c0.47,0.407,0.939,0.814,1.383,1.197c0.215,0.196,0.443,0.364,0.623,0.563
	   c0.188,0.19,0.364,0.372,0.528,0.539c0.653,0.667,1.089,1.11,1.089,1.11l-0.172,0.156c0,0-0.435-0.438-1.085-1.1
	   c-0.163-0.165-0.34-0.344-0.524-0.532c-0.181-0.197-0.407-0.363-0.622-0.558c-0.439-0.377-0.907-0.78-1.376-1.184
	   c-0.497-0.364-0.995-0.728-1.462-1.068c-0.456-0.354-0.923-0.633-1.321-0.872c-0.794-0.486-1.322-0.811-1.322-0.811
	   L370.214,414.367z"/>
   
	   <rect x="378.347" y="421.665" transform="matrix(0.6328 0.7743 -0.7743 0.6328 465.6927 -138.4694)" fill="#FFFFFF" width="0.994" height="0.224"/>
   
	   <rect x="383.343" y="431.307" transform="matrix(0.2146 0.9767 -0.9767 0.2146 722.7996 -36.0863)" fill="#FFFFFF" width="0.992" height="0.187"/>
   <path fill="#FFFFFF" d="M384.087,438.791c0,0-0.148,0.604-0.369,1.506c-0.056,0.227-0.115,0.473-0.18,0.73
	   c-0.055,0.262-0.186,0.518-0.281,0.793c-0.212,0.545-0.438,1.127-0.664,1.709c-0.298,0.547-0.596,1.094-0.874,1.604
	   c-0.149,0.25-0.253,0.52-0.425,0.727c-0.157,0.217-0.308,0.42-0.444,0.609c-0.55,0.754-0.917,1.258-0.917,1.258l-0.098-0.084
	   c0,0,0.361-0.5,0.905-1.252c0.137-0.188,0.283-0.391,0.438-0.605c0.171-0.205,0.271-0.473,0.42-0.721
	   c0.275-0.512,0.568-1.055,0.862-1.598c0.222-0.576,0.442-1.15,0.649-1.691c0.095-0.273,0.225-0.521,0.278-0.785
	   c0.063-0.258,0.121-0.504,0.176-0.73c0.218-0.902,0.363-1.508,0.363-1.508L384.087,438.791z"/>
   
	   <rect x="378.375" y="448.741" transform="matrix(-0.6997 0.7144 -0.7144 -0.6997 964.6078 492.1361)" fill="#FFFFFF" width="0.992" height="0.119"/>
   
	   <rect x="368.832" y="453.526" transform="matrix(-0.9993 0.0373 -0.0373 -0.9993 755.3368 893.0272)" fill="#FFFFFF" width="0.994" height="0.082"/>
   <path fill="#FFFFFF" d="M362.28,451.589c0,0-0.124-0.096-0.341-0.26c-0.208-0.172-0.542-0.377-0.879-0.699
	   c-0.328-0.332-0.761-0.674-1.111-1.141c-0.186-0.227-0.386-0.453-0.57-0.695c-0.167-0.256-0.339-0.516-0.51-0.775
	   c-0.184-0.252-0.317-0.535-0.442-0.813c-0.12-0.281-0.287-0.537-0.349-0.826c-0.08-0.281-0.158-0.553-0.232-0.809
	   c-0.052-0.262-0.07-0.516-0.104-0.746c-0.086-0.461-0.031-0.852-0.031-1.123c0.008-0.271,0.013-0.426,0.013-0.426l0.022,0.002
	   c0,0-0.004,0.152-0.012,0.424c0.002,0.271-0.051,0.662,0.036,1.119c0.034,0.23,0.053,0.482,0.105,0.742
	   c0.073,0.256,0.152,0.523,0.233,0.803c0.061,0.285,0.229,0.543,0.35,0.822c0.127,0.279,0.262,0.557,0.444,0.809
	   c0.171,0.258,0.342,0.518,0.51,0.77c0.187,0.242,0.387,0.467,0.57,0.689c0.354,0.467,0.783,0.803,1.109,1.131
	   c0.336,0.32,0.672,0.525,0.881,0.697c0.216,0.164,0.341,0.256,0.341,0.256L362.28,451.589z"/>
   
	   <rect x="357.539" y="441.82" transform="matrix(0.3059 -0.9521 0.9521 0.3059 -172.1326 647.5558)" fill="#FFFFFF" width="0.993" height="0.018"/>
   <polygon fill="#FFFFFF" points="121.686,365.36 126.863,356.885 129.379,358.415 124.229,366.91 		"/>
   
	   <rect x="128.416" y="354.939" transform="matrix(0.5463 -0.8376 0.8376 0.5463 -240.0372 269.6857)" fill="#FFFFFF" width="0.994" height="2.938"/>
   
	   <rect x="134.624" y="345.931" transform="matrix(0.5885 -0.8085 0.8085 0.5885 -225.2587 252.2088)" fill="#FFFFFF" width="0.994" height="2.9"/>
   <polygon fill="#FFFFFF" points="138.489,340.537 144.8,332.868 146.996,334.67 140.712,342.36 		"/>
   
	   <rect x="146.36" y="331.215" transform="matrix(0.6589 -0.7523 0.7523 0.6589 -200.13 223.9523)" fill="#FFFFFF" width="0.993" height="2.834"/>
   
	   <rect x="153.762" y="323.21" transform="matrix(0.6972 -0.7169 0.7169 0.6972 -185.9936 208.873)" fill="#FFFFFF" width="0.993" height="2.795"/>
   <polygon fill="#FFFFFF" points="158.606,318.324 165.938,311.626 167.786,313.643 160.479,320.366 		"/>
   
	   <rect x="167.493" y="310.293" transform="matrix(0.7589 -0.6512 0.6512 0.7589 -162.4485 184.5326)" fill="#FFFFFF" width="0.997" height="2.729"/>
   
	   <rect x="175.95" y="303.403" transform="matrix(0.7914 -0.6113 0.6113 0.7914 -149.4823 171.4234)" fill="#FFFFFF" width="0.992" height="2.69"/>
   <polygon fill="#FFFFFF" points="181.663,299.184 189.867,293.588 191.354,295.761 183.169,301.383 		"/>
   
	   <rect x="191.368" y="292.546" transform="matrix(0.8427 -0.5384 0.5384 0.8427 -128.0261 149.5158)" fill="#FFFFFF" width="0.993" height="2.625"/>
   
	   <rect x="200.708" y="286.915" transform="matrix(0.8696 -0.4938 0.4938 0.8696 -116.0764 136.9468)" fill="#FFFFFF" width="0.993" height="2.586"/>
   <path fill="#FFFFFF" d="M408.323,285.788c0,0,0.539,0.307,1.351,0.764c0.404,0.23,0.877,0.498,1.385,0.784
	   c0.253,0.144,0.515,0.291,0.78,0.441l0.425,0.244l0.396,0.241c2.122,1.29,4.244,2.579,4.244,2.579l-0.931,1.536
	   c0,0-2.126-1.282-4.252-2.563l-0.398-0.24l-0.378-0.213c-0.266-0.15-0.527-0.297-0.781-0.439c-0.508-0.285-0.98-0.551-1.388-0.778
	   c-0.812-0.456-1.353-0.76-1.353-0.76L408.323,285.788z"/>
   
	   <rect x="417.172" y="291.509" transform="matrix(0.8372 0.5469 -0.5469 0.8372 227.8864 -180.8084)" fill="#FFFFFF" width="0.994" height="1.79"/>
   
	   <rect x="426.084" y="297.872" transform="matrix(0.79 0.6131 -0.6131 0.79 272.757 -198.8062)" fill="#FFFFFF" width="0.994" height="1.752"/>
   <polygon fill="#FFFFFF" points="432.941,302.843 440.16,309.659 439.001,310.893 431.757,304.101 		"/>
   
	   <rect x="440.131" y="310.476" transform="matrix(0.6904 0.7234 -0.7234 0.6904 361.6046 -222.3738)" fill="#FFFFFF" width="0.993" height="1.684"/>
   
	   <rect x="447.347" y="318.712" transform="matrix(0.6298 0.7767 -0.7767 0.6298 413.9695 -229.5725)" fill="#FFFFFF" width="0.994" height="1.648"/>
   <polygon fill="#FFFFFF" points="453.056,324.992 458.534,333.273 457.212,334.152 451.703,325.891 		"/>
   <path fill="#FFFFFF" d="M460.599,466.513c0,0-0.078,0.137-0.213,0.371c-0.068,0.117-0.15,0.26-0.244,0.424
	   c-0.101,0.168-0.213,0.355-0.336,0.561c-0.488,0.793-1.142,1.85-1.792,2.908c-0.313,0.533-0.688,1.041-1.02,1.525
	   c-0.334,0.482-0.646,0.936-0.915,1.32c-0.535,0.771-0.894,1.289-0.894,1.289l-0.861-0.631c0,0,0.35-0.506,0.871-1.266
	   c0.262-0.381,0.566-0.822,0.895-1.299c0.322-0.479,0.688-0.969,0.999-1.51c0.647-1.059,1.296-2.117,1.78-2.914
	   c0.117-0.193,0.225-0.371,0.318-0.529c0.093-0.166,0.175-0.309,0.242-0.426c0.133-0.236,0.211-0.371,0.211-0.371L460.599,466.513z
	   "/>
   
	   <rect x="453.378" y="475.251" transform="matrix(-0.6075 0.7943 -0.7943 -0.6075 1107.527 404.3134)" fill="#FFFFFF" width="0.993" height="1.058"/>
   
	   <rect x="446.183" y="483.519" transform="matrix(-0.7086 0.7056 -0.7056 -0.7086 1104.7354 511.8137)" fill="#FFFFFF" width="0.994" height="1.023"/>
   <path fill="#FFFFFF" d="M441.487,489.425c0,0-1.963,1.521-3.924,3.041c-2.07,1.41-4.141,2.82-4.141,2.82l-0.52-0.813
	   c0,0,2.028-1.391,4.059-2.781c1.957-1.527,3.911-3.057,3.911-3.057L441.487,489.425z"/>
   
	   <rect x="338.109" y="488.227" transform="matrix(-0.7002 -0.714 0.714 -0.7002 226.9044 1072.3411)" fill="#FFFFFF" width="0.993" height="0.604"/>
   <path fill="#FFFFFF" d="M333.537,483.015c0,0-1.349-2.084-2.696-4.168c-1.146-2.225-2.292-4.449-2.292-4.449l0.497-0.227
	   c0,0,1.132,2.186,2.266,4.369c1.356,2.078,2.713,4.158,2.713,4.158L333.537,483.015z"/>
   
	   <rect x="327.686" y="472.659" transform="matrix(-0.3848 -0.923 0.923 -0.3848 17.93 957.8078)" fill="#FFFFFF" width="0.992" height="0.538"/>
   
	   <rect x="324.611" y="462.247" transform="matrix(-0.1772 -0.9842 0.9842 -0.1772 -72.4811 864.3938)" fill="#FFFFFF" width="0.992" height="0.5"/>
   <path fill="#FFFFFF" d="M324.075,455.07c0,0,0.053-2.482,0.104-4.965c0.3-2.48,0.599-4.963,0.599-4.963l0.436,0.074
	   c0,0-0.288,2.449-0.575,4.896c-0.042,2.48-0.085,4.965-0.085,4.965L324.075,455.07z"/>
   
	   <rect x="324.754" y="443.519" transform="matrix(0.1997 -0.9799 0.9799 0.1997 -174.5018 673.8162)" fill="#FFFFFF" width="0.993" height="0.434"/>
   
	   <rect x="328.093" y="433.106" transform="matrix(0.4075 -0.9132 0.9132 0.4075 -201.0063 556.8007)" fill="#FFFFFF" width="0.995" height="0.396"/>
   <path fill="#FFFFFF" d="M331.977,426.675c0,0,0.368-0.498,0.921-1.248c0.139-0.188,0.288-0.391,0.446-0.604
	   c0.148-0.223,0.352-0.426,0.54-0.65c0.386-0.441,0.797-0.914,1.208-1.387c0.445-0.438,0.893-0.873,1.31-1.283
	   c0.408-0.421,0.831-0.768,1.193-1.064c0.721-0.599,1.2-0.996,1.2-0.996l0.205,0.268c0,0-0.475,0.396-1.185,0.99
	   c-0.358,0.293-0.774,0.637-1.176,1.056c-0.412,0.406-0.853,0.84-1.29,1.273c-0.403,0.465-0.806,0.93-1.184,1.367
	   c-0.185,0.221-0.383,0.418-0.531,0.641c-0.156,0.215-0.307,0.418-0.443,0.605c-0.55,0.752-0.917,1.254-0.917,1.254
	   L331.977,426.675z"/>
   
	   <rect x="339.608" y="418.528" transform="matrix(0.8233 -0.5676 0.5676 0.8233 -177.5587 267.0343)" fill="#FFFFFF" width="0.989" height="0.329"/>
   
	   <rect x="349.485" y="414.029" transform="matrix(0.9685 -0.249 0.249 0.9685 -92.1111 100.197)" fill="#FFFFFF" width="0.992" height="0.291"/>
   <path fill="#FFFFFF" d="M357.341,413.06c0,0,0.621,0.019,1.551,0.046c0.465,0.011,1.008,0.021,1.59,0.103
	   c0.581,0.063,1.201,0.134,1.821,0.202c0.61,0.117,1.224,0.234,1.797,0.347c0.285,0.063,0.566,0.093,0.822,0.175
	   c0.258,0.072,0.501,0.141,0.727,0.203c0.899,0.251,1.499,0.418,1.499,0.418l-0.071,0.222c0,0-0.595-0.164-1.488-0.409
	   c-0.224-0.063-0.467-0.128-0.723-0.198c-0.253-0.082-0.533-0.112-0.815-0.172c-0.569-0.107-1.177-0.223-1.783-0.337
	   c-0.613-0.066-1.227-0.133-1.8-0.194c-0.572-0.079-1.116-0.087-1.582-0.096c-0.931-0.023-1.549-0.039-1.549-0.039L357.341,413.06z
	   "/>
   
	   <rect x="368.036" y="415.057" transform="matrix(0.9354 0.3535 -0.3535 0.9354 170.5548 -103.4695)" fill="#FFFFFF" width="0.992" height="0.226"/>
   
	   <rect x="377.158" y="420.864" transform="matrix(0.6744 0.7384 -0.7384 0.6744 433.7954 -141.7817)" fill="#FFFFFF" width="0.992" height="0.187"/>
   <path fill="#FFFFFF" d="M381.568,427.273c0,0,0.173,0.596,0.436,1.488c0.063,0.225,0.136,0.465,0.21,0.723
	   c0.082,0.254,0.096,0.541,0.15,0.826c0.089,0.578,0.186,1.195,0.279,1.813c0.016,0.623,0.031,1.244,0.046,1.828
	   c-0.005,0.291,0.04,0.576-0.006,0.84c-0.028,0.268-0.056,0.518-0.08,0.75c-0.099,0.93-0.166,1.547-0.166,1.547l-0.126-0.02
	   c0,0,0.063-0.615,0.159-1.537c0.022-0.232,0.05-0.48,0.077-0.746c0.045-0.262-0.001-0.543,0.003-0.834
	   c-0.018-0.578-0.034-1.195-0.053-1.814c-0.097-0.607-0.192-1.217-0.282-1.789c-0.056-0.283-0.067-0.564-0.151-0.818
	   c-0.075-0.256-0.147-0.498-0.213-0.721c-0.263-0.893-0.439-1.486-0.439-1.486L381.568,427.273z"/>
   
	   <rect x="381.581" y="438.488" transform="matrix(-0.2481 0.9687 -0.9687 -0.2481 901.7056 177.2176)" fill="#FFFFFF" width="0.992" height="0.122"/>
   
	   <rect x="375.696" y="447.375" transform="matrix(-0.846 0.5332 -0.5332 -0.846 933.0055 625.3542)" fill="#FFFFFF" width="0.993" height="0.083"/>
   <path fill="#FFFFFF" d="M369.081,449.261c0,0-0.153-0.02-0.424-0.053c-0.268-0.047-0.657-0.057-1.111-0.168
	   c-0.45-0.123-0.995-0.203-1.533-0.432c-0.272-0.104-0.56-0.199-0.841-0.316c-0.273-0.139-0.552-0.277-0.828-0.418
	   c-0.286-0.127-0.545-0.303-0.791-0.48c-0.245-0.184-0.519-0.322-0.714-0.543c-0.211-0.201-0.414-0.396-0.607-0.584
	   c-0.175-0.199-0.317-0.41-0.46-0.594c-0.306-0.355-0.454-0.723-0.591-0.957c-0.129-0.238-0.203-0.375-0.203-0.375l0.022-0.01
	   c0,0,0.073,0.135,0.202,0.373c0.138,0.234,0.286,0.598,0.592,0.951c0.144,0.182,0.285,0.393,0.462,0.59
	   c0.191,0.184,0.395,0.377,0.604,0.578c0.194,0.217,0.469,0.355,0.714,0.539c0.25,0.178,0.503,0.352,0.789,0.477
	   c0.276,0.139,0.555,0.277,0.826,0.414c0.282,0.115,0.567,0.209,0.839,0.311c0.537,0.229,1.079,0.303,1.526,0.424
	   c0.45,0.109,0.844,0.119,1.109,0.164c0.271,0.033,0.425,0.053,0.425,0.053L369.081,449.261z"/>
   
	   <rect x="360.02" y="442.921" transform="matrix(-0.2114 -0.9774 0.9774 -0.2114 3.8154 888.9368)" fill="#FFFFFF" width="0.993" height="0.017"/>
</g>
<g>
   <path fill="#37AFE4" d="M256.657,420.944h-5.136l129.311,145.387v-70.578c-1.144,0-2.547-0.365-4-0.467v60.529L260.433,424.945"/>
</g>

   <rect x="357.539" y="414.717" fill="none" stroke="#FFFFFF" strokeWidth="3" strokeMiterlimit="10" width="19.123" height="19.125"/>
</g>
<text transform="matrix(1 0 0 1 112.1892 537.3335)" fontFamily="'AngsanaNew'" fontSize="70.7392">Aubichol</text>
</svg>